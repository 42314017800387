import { Col } from 'antd';
import styled, { css, keyframes } from 'styled-components';

export const Container = styled(Col).attrs({
  xxl: 22, md: 22, sm: 22, xs: 22,
})`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
`;

export const SkeletonAnimation = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

export const DefaultSkeleton = css`
  overflow: hidden;
  position: relative;
  background-color: #d7d4de;
  :after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: ${SkeletonAnimation} 1.5s infinite;
    content: "";
  }
`;
