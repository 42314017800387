import styled, { css } from 'styled-components';
import { Input } from 'antd';
import colors from '@colors';
// @ts-ignore
import { useStyledErrorInput, useStyledErrorLabel } from '@hooks';

import Error from 'types/Error';
import IInput from 'types/Input';

export const LabeledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 370px;
  border-radius: 24px;
`;

export const Label = styled.label`
  margin-bottom: 6px;
`;

export const ErrorMessage = styled.label<Error>`
  font-size: 12px;
  margin-top: 6px;
  ${({ errortype }) => useStyledErrorLabel(errortype)}
`;

export const BaseInput = css`
  border-right-width: 1px !important;
  border-radius: 24px;
  border-width: 1px;
  span i {
    padding-right: 1px;
    padding-bottom: 1px;
  }
  &:hover {
    border-right-width: 1px !important;
    border: 1px solid ${colors.primary};
  }
  &:focus {
    border-right-width: 1px !important;
    border: 1px solid ${colors.primary};
    outline: 0;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -antd-wave-shadow-color: none !important;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
  .ant-input-suffix i {
    -webkit-text-stroke: 0;
  }
`;

export const DefaultInput = styled(Input)<IInput | Error>`
  ${BaseInput}
  ${({ height, errortype }) => `
      ${useStyledErrorInput(errortype)}
      width: 100%;
      height: ${height};
    `}
`;

export const DefaultInputPassword = styled(Input.Password)<IInput | Error>`
  ${BaseInput}
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -antd-wave-shadow-color: none !important;
  ${({ height, errortype }) => `
      ${useStyledErrorInput(errortype)}
      width: 100%;
      height: ${height};
    `}
`;
