export default {
  primary: '#FF4E00',
  secondary: '#2C363F',
  foreground: '#EBECEC',
  border: '#efeff4',
  defaultHover: '#2C363F',
  textHighEmphasis: '#2C363F',
  textMediumEmphasis: '#90959A',
  yellowWarn: '#FAAD14',
  blueInfo: '#1890FF',
  redError: '#ff4d4f',
  greenSuccess: '#5ab864',
  default: '#2C363F',
  border2: '#d9d9d9',
  white: '#ffffff',
};
