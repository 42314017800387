import { lazy } from 'react';

import Home from '@pages/Home';

export default [
  {
    path: '/',
    exact: true,
    component: Home,
    name: 'Início',
  },
  {
    path: '/faq',
    exact: true,
    component: lazy(() => import('@pages/Faq')),
  },
  {
    path: '/signup',
    exact: true,
    component: lazy(() => import('@pages/Signup')),
  },
  {
    path: '/signin',
    exact: true,
    component: lazy(() => import('@pages/Signin')),
  },
  {
    path: '/confirmemail',
    exact: true,
    component: lazy(() => import('@pages/SimulationConfirmEmail')),
  },
  {
    path: '/sobre',
    exact: true,
    component: lazy(() => import('@pages/AboutUs')),
  },
  {
    path: '/busca',
    exact: true,
    component: lazy(() => import('@pages/DetailedSearch')),
    name: 'Busca',
  },
  {
    path: '/faleconosco',
    exact: true,
    component: lazy(() => import('@pages/ContactUs')),
  },
  {
    path: '/password/reset',
    exact: true,
    component: lazy(() => import('@pages/ResetPassword')),
  },
  {
    path: '/password/forgot',
    exact: true,
    component: lazy(() => import('@pages/ForgotPassword')),
  },
  {
    path: '/empreendimento/:slug',
    exact: true,
    component: lazy(() => import('@pages/BuildDetails')),
    name: 'Empreendimento',
  },
  {
    privateRoute: true,
    path: '/simulador/:simulationId?',
    exact: true,
    component: lazy(() => import('@pages/Simulator')),
  },
  {
    path: '/simulation/values',
    exact: true,
    component: lazy(() => import('@pages/Simulator/SimulationValuesModal')),
  },
  {
    path: '/auth',
    exact: true,
    component: lazy(() => import('@pages/Auth')),
  },
  {
    privateRoute: true,
    path: '/favoritos',
    exact: true,
    component: lazy(() => import('@pages/Favorites')),
  },
  {
    privateRoute: true,
    path: '/meus-pre-lancamentos',
    exact: true,
    component: lazy(() => import('@pages/MyPreReleases')),
  },
  {
    privateRoute: true,
    path: '/minhaconta',
    exact: true,
    component: lazy(() => import('@pages/MyAccount')),
  },
  {
    privateRoute: true,
    path: '/negociacoes',
    exact: true,
    component: lazy(() => import('@pages/Negotiations')),
  },
  {
    privateRoute: true,
    path: '/confirmarsenha',
    exact: true,
    component: lazy(() => import('@pages/ConfirmPassword')),
  },
  {
    privateRoute: true,
    path: '/cadastro',
    exact: true,
    component: lazy(() => import('@pages/FullRegister')),
  },
  {
    privateRoute: true,
    path: '/cadastro/pf/3',
    exact: true,
    component: lazy(() => import('@pages/FullRegister/PessoaFisicaStep03')),
  },
  {
    privateRoute: true,
    path: '/cadastro/pf/4',
    exact: true,
    component: lazy(() => import('@pages/FullRegister/PessoaFisicaStep04')),
  },
  {
    privateRoute: true,
    path: '/cadastro/pf/5',
    exact: true,
    component: lazy(() => import('@pages/FullRegister/PessoaFisicaStep05')),
  },
  {
    privateRoute: true,
    path: '/cadastro/pf/6',
    exact: true,
    component: lazy(() => import('@pages/FullRegister/PessoaFisicaStep06')),
  },
  {
    privateRoute: true,
    path: '/cadastro/pf/7',
    exact: true,
    component: lazy(() => import('@pages/FullRegister/PessoaFisicaStep07')),
  },
  {
    privateRoute: true,
    path: '/cadastro/pj/3',
    exact: true,
    component: lazy(() => import('@pages/FullRegister/PessoaJuridicaStep03')),
  },
  {
    privateRoute: true,
    path: '/cadastro/pj/4',
    exact: true,
    component: lazy(() => import('@pages/FullRegister/PessoaJuridicaStep04')),
  },
  {
    privateRoute: true,
    path: '/cadastro/pj/5',
    exact: true,
    component: lazy(() => import('@pages/FullRegister/PessoaJuridicaStep05')),
  },
  {
    privateRoute: true,
    path: '/cadastro/pj/6',
    exact: true,
    component: lazy(() => import('@pages/FullRegister/PessoaJuridicaStep06')),
  },
  {
    privateRoute: true,
    path: '/cadastro/pj/7',
    exact: true,
    component: lazy(() => import('@pages/FullRegister/PessoaJuridicaStep07')),
  },
  {
    privateRoute: true,
    path: '/cadastro/pj/8',
    exact: true,
    component: lazy(() => import('@pages/FullRegister/PessoaJuridicaStep08')),
  },
  {
    privateRoute: true,
    path: '/cadastro/pj/9',
    exact: true,
    component: lazy(() => import('@pages/FullRegister/PessoaJuridicaStep09')),
  },
  {
    privateRoute: true,
    path: '/cadastro/finalizacao',
    exact: true,
    component: lazy(() => import('@pages/FullRegister/FinalPropose')),
  },
  {
    privateRoute: true,
    path: '/cadastro/exclusivo',
    exact: true,
    component: lazy(() => import('@pages/ExclusiveRegister')),
  },
  {
    privateRoute: true,
    path: '/cadastro/exclusivo/pf/3',
    exact: true,
    component: lazy(() => import('@pages/ExclusiveRegister/PessoaFisicaStep03')),
  },
  {
    privateRoute: true,
    path: '/cadastro/exclusivo/pf/4',
    exact: true,
    component: lazy(() => import('@pages/ExclusiveRegister/PessoaFisicaStep04')),
  },
  {
    privateRoute: true,
    path: '/cadastro/exclusivo/pf/5',
    exact: true,
    component: lazy(() => import('@pages/ExclusiveRegister/PessoaFisicaStep05')),
  },
  {
    privateRoute: true,
    path: '/cadastro/exclusivo/pf/6',
    exact: true,
    component: lazy(() => import('@pages/ExclusiveRegister/PessoaFisicaStep06')),
  },
  {
    privateRoute: true,
    path: '/cadastro/exclusivo/pf/7',
    exact: true,
    component: lazy(() => import('@pages/ExclusiveRegister/PessoaFisicaStep07')),
  },
  {
    privateRoute: true,
    path: '/cadastro/exclusivo/pj/3',
    exact: true,
    component: lazy(() => import('@pages/ExclusiveRegister/PessoaJuridicaStep03')),
  },
  {
    privateRoute: true,
    path: '/cadastro/exclusivo/pj/4',
    exact: true,
    component: lazy(() => import('@pages/ExclusiveRegister/PessoaJuridicaStep04')),
  },
  {
    privateRoute: true,
    path: '/cadastro/exclusivo/pj/5',
    exact: true,
    component: lazy(() => import('@pages/ExclusiveRegister/PessoaJuridicaStep05')),
  },
  {
    privateRoute: true,
    path: '/cadastro/exclusivo/pj/6',
    exact: true,
    component: lazy(() => import('@pages/ExclusiveRegister/PessoaJuridicaStep06')),
  },
  {
    privateRoute: true,
    path: '/cadastro/exclusivo/pj/7',
    exact: true,
    component: lazy(() => import('@pages/ExclusiveRegister/PessoaJuridicaStep07')),
  },
  {
    privateRoute: true,
    path: '/cadastro/exclusivo/pj/8',
    exact: true,
    component: lazy(() => import('@pages/ExclusiveRegister/PessoaJuridicaStep08')),
  },
  {
    privateRoute: true,
    path: '/cadastro/exclusivo/pj/9',
    exact: true,
    component: lazy(() => import('@pages/ExclusiveRegister/PessoaJuridicaStep09')),
  },
  {
    path: '/lancamentos',
    exact: true,
    component: lazy(() => import('@pages/DetailedSearch')),
    name: 'Lançamentos',
  },
  {
    path: '/pre-lancamentos',
    exact: true,
    component: lazy(() => import('@pages/DetailedSearch')),
    name: 'Pre-lançamentos',
  },
  {
    path: '*',
    component: lazy(() => import('@pages/Errors/404')),
  },
];
