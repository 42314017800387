// eslint-disable-next-line
import React from 'react';
import IContainedButton from 'types/Button';
import colors from '@colors';
import { DefaultButton } from './styles';

function Button({
  id,
  children,
  color,
  state,
  width,
  height,
  onClick,
  type,
  buttonClass,
  disabled,
}: IContainedButton) {
  return (
    <DefaultButton
      id={id}
      width={width}
      buttonClass={buttonClass}
      height={height}
      state={state}
      color={color}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {children}
    </DefaultButton>
  );
}

Button.defaultProps = {
  label: '',
  state: 'active',
  color: colors.primary,
  width: '370px',
  height: '40px',
  type: 'button',
  buttonClass: 'contained',
  disabled: false,
};

export default Button;
