import {
  removeDiscount,
  setDiscount1,
  setDiscount2,
  setDiscount3,
  setFinalValue,
} from '@actions/simulator';
import { monthDiff, yearDiff } from '@util';
import { useDispatch, useSelector } from 'react-redux';
import useDidMountEffect from './useDidMountEffect';

function useCalculateSimulation() {
  const {
    step,
    price,
    intake,
    discount,
    stepsData,
    contribution,
    yearlyPayment,
    transitioning,
    monthlyPayment,
    realEstateData,
    propertySelected,
    oldYearlyPayment,
    singleInstallment,
    totalYearlyPayment,
    totalMonthlyPayment,
    oldSingleInstallment,
    intakeMonthlyInstallments,
    inferIntakeMonthlyInstallments,
  } = useSelector(({ simulator }: any) => simulator);

  const dispatch = useDispatch();

  const {
    valorAporte,
    valorEntrada,
    valorParcelaUnica,
  }: {
    price: number;
    valorAporte: number;
    valorMensais: number;
    valorEntrada: number;
    valorParcelaUnica: number;
    valorAnualSemestral: number;
  } = propertySelected || {
    valorEntrada: 0,
    valorAporte: 0,
    valorMensais: 0,
    valorParcelaUnica: 0,
    valorAnualSemestral: 0,
  };

  useDidMountEffect(() => {
    const { dataEntrega } = realEstateData;

    if (!transitioning) {
      if (stepsData[5] && step === 5) {
        const {
          value: { intake },
        } = stepsData[5];
        dispatch(setFinalValue({ intake }));
      }

      if (stepsData[6] && step === 6) {
        const {
          value: { monthlyInstallment },
        } = stepsData[6];

        const totalInstallmentsUntilDelivery = monthDiff(new Date(), new Date(dataEntrega))
          - inferIntakeMonthlyInstallments;

        const totalMonthlyPayment = Math.round(
          monthlyInstallment * totalInstallmentsUntilDelivery,
        );

        dispatch(setFinalValue({ monthlyPayment: monthlyInstallment }));

        dispatch(
          setFinalValue({
            totalMonthlyPayment,
          }),
        );
      }

      if (stepsData[7] && step === 7) {
        const { value } = stepsData[7];
        const newBalanceDebt = price - discount - contribution;
        dispatch(setFinalValue({ balanceDebt: newBalanceDebt }));
        if (value === 1) {
          dispatch(setFinalValue({ yearlyPayment: oldYearlyPayment }));
          dispatch(setFinalValue({ singleInstallment: oldSingleInstallment }));
        } else if (value === 2) {
          const newYearlyPayment = oldYearlyPayment / 2;
          dispatch(setFinalValue({ yearlyPayment: newYearlyPayment }));
          dispatch(setFinalValue({ singleInstallment: oldSingleInstallment }));
        } else if (!value) {
          let newSingleIntallment = !singleInstallment
            ? 0
            : singleInstallment + totalYearlyPayment;
          dispatch(setFinalValue({ yearlyPayment: 0 }));
          if (newSingleIntallment > valorParcelaUnica) {
            newSingleIntallment = valorParcelaUnica;
          }
          dispatch(
            setFinalValue({
              balanceDebt: price - intake - totalMonthlyPayment - discount,
            }),
          );
          dispatch(
            setFinalValue({
              singleInstallment: newSingleIntallment,
            }),
          );
        }
      }

      if (stepsData[8] && step === 8) {
        const {
          value: { yearlyOrSemiannualValue },
        } = stepsData[8];

        const { value } = stepsData[7];

        const totalYearlyOrSemiannualValue = yearlyOrSemiannualValue
          * (yearDiff(new Date(), new Date(dataEntrega)) * value);

        const contribution = valorAporte
          - intake
          - totalMonthlyPayment
          - totalYearlyOrSemiannualValue;
        const summation = valorParcelaUnica;
        const newSingleInstallmentValue = parseFloat(
          (contribution * (valorParcelaUnica / summation)).toFixed(2),
        );

        if (!stepsData[9]) {
          dispatch(
            setFinalValue({
              singleInstallment: newSingleInstallmentValue,
            }),
          );
        }

        dispatch(setFinalValue({ yearlyPayment: yearlyOrSemiannualValue }));
      }

      if (stepsData[9] && step === 9) {
        const { value } = stepsData[9];
        dispatch(
          setFinalValue({
            singleInstallment: value,
          }),
        );
      }
    }
  }, [stepsData]);

  useDidMountEffect(() => {
    if (!transitioning && step < 10 && intake && price) {
      if (intake === price) {
        dispatch(setDiscount1());
        return;
      }
      dispatch(removeDiscount());

      if (
        step >= 5
        && intake >= valorEntrada
        && intake < valorAporte
        && stepsData[5].value.willSplitIntake === 0
      ) {
        dispatch(setDiscount2());
      } else if (intake >= valorAporte && intake < price) {
        dispatch(setDiscount3());
      } else {
        dispatch(removeDiscount());
      }
    }
  }, [intake, intakeMonthlyInstallments, stepsData[5]?.value?.willSplitIntake]);

  useDidMountEffect(() => {
    if (
      contribution >= price * 0.8
      && yearlyPayment === 0
      && monthlyPayment === 0
      && singleInstallment === 0
    ) {
      dispatch(setFinalValue({ balanceDebt: 0 }));
    } else {
      dispatch(setFinalValue({ balanceDebt: price - contribution - discount }));
    }
  }, [contribution]);

  useDidMountEffect(() => {
    if (!transitioning) {
      let newIntake = 0;
      let newTotalMonthlyPayment = 0;
      let newTotalYearlyOrSemiannualValue = 0;
      let newSingleInstallment = 0;

      const { dataEntrega } = realEstateData;

      if (stepsData[5]?.value) {
        const {
          value: { intake: storeIntake },
        } = stepsData[5];

        if (step > 5) {
          newIntake = intake;
        } else {
          newIntake = storeIntake;
        }
      }
      if (stepsData[6]?.value) {
        const {
          value: { monthlyInstallment },
        } = stepsData[6];

        const totalInstallmentsUntilDelivery = monthDiff(new Date(), new Date(dataEntrega))
          - inferIntakeMonthlyInstallments;
        if (step > 6) {
          newTotalMonthlyPayment = Math.round(
            monthlyPayment * totalInstallmentsUntilDelivery,
          );
        } else {
          newTotalMonthlyPayment = Math.round(
            monthlyInstallment * totalInstallmentsUntilDelivery,
          );
        }
      }

      if (stepsData[8]?.value) {
        const {
          value: { yearlyOrSemiannualValue },
        } = stepsData[8];
        const { value: isAnnualOrSemiAnnual } = stepsData[7];
        if (step > 8) {
          newTotalYearlyOrSemiannualValue = yearlyPayment
            * (yearDiff(new Date(), new Date(dataEntrega))
              * isAnnualOrSemiAnnual);
        } else {
          newTotalYearlyOrSemiannualValue = yearlyOrSemiannualValue
            * (yearDiff(new Date(), new Date(dataEntrega))
              * isAnnualOrSemiAnnual);
        }
      }

      if (stepsData[9]?.value) {
        const { value } = stepsData[9];
        if (step > 9) {
          newSingleInstallment = singleInstallment;
        } else {
          newSingleInstallment = value;
        }
      }

      const contribution = newIntake
        + newSingleInstallment
        + newTotalMonthlyPayment
        + newTotalYearlyOrSemiannualValue;

      dispatch(
        setFinalValue({
          contribution,
        }),
      );
    }
  }, [
    intake,
    inferIntakeMonthlyInstallments,
    singleInstallment,
    yearlyPayment,
    monthlyPayment,
  ]);

  useDidMountEffect(() => {
    if (!transitioning && step === 6) {
      const { dataEntrega } = realEstateData;
      const summation = totalYearlyPayment + valorParcelaUnica;
      let singleInstallmentRestDivider = 0;
      const contribution = valorAporte - intake - totalMonthlyPayment;

      let newSingleInstallmentValue = parseFloat(
        (contribution * (valorParcelaUnica / summation)).toFixed(2),
      );

      if (newSingleInstallmentValue > valorParcelaUnica) {
        newSingleInstallmentValue = valorParcelaUnica;
        singleInstallmentRestDivider = (newSingleInstallmentValue - valorParcelaUnica) / 2;
      }

      const newYearlyValue = parseFloat(
        (
          contribution
          * ((totalYearlyPayment + singleInstallmentRestDivider) / summation)
        ).toFixed(2),
      );

      const newYearlyPayment = newYearlyValue / yearDiff(new Date(), new Date(dataEntrega));

      dispatch(
        setFinalValue({
          singleInstallmentRestDivider,
        }),
      );
      dispatch(setFinalValue({ singleInstallment: newSingleInstallmentValue }));
      dispatch(setFinalValue({ yearlyPayment: newYearlyPayment }));
      dispatch(setFinalValue({ oldYearlyPayment: newYearlyPayment }));
      dispatch(
        setFinalValue({ oldSingleInstallment: newSingleInstallmentValue }),
      );
    }
  }, [monthlyPayment, totalMonthlyPayment]);

  useDidMountEffect(() => {
    if (!transitioning && step === 5) {
      const { dataEntrega } = realEstateData;
      let singleInstallmentRestDivider = 0;

      const summation = totalMonthlyPayment + totalYearlyPayment + valorParcelaUnica;

      const contribution = valorAporte - intake;

      let newSingleInstallmentValue = parseFloat(
        (contribution * (valorParcelaUnica / summation)).toFixed(2),
      );

      if (newSingleInstallmentValue > valorParcelaUnica) {
        newSingleInstallmentValue = valorParcelaUnica;
        singleInstallmentRestDivider = (newSingleInstallmentValue - valorParcelaUnica) / 2;
      }

      const newMonthlyValue = parseFloat(
        (
          contribution
          * ((totalMonthlyPayment + singleInstallmentRestDivider) / summation)
        ).toFixed(2),
      );

      const newYearlyValue = parseFloat(
        (
          contribution
          * ((totalYearlyPayment + singleInstallmentRestDivider) / summation)
        ).toFixed(2),
      );

      const newMonthlyPayment = parseFloat(
        (
          newMonthlyValue
          / (monthDiff(new Date(), new Date(dataEntrega))
            - inferIntakeMonthlyInstallments)
        ).toFixed(2),
      );

      const newYearlyPayment = newYearlyValue / yearDiff(new Date(), new Date(dataEntrega));

      dispatch(setFinalValue({ monthlyPayment: newMonthlyPayment }));
      dispatch(setFinalValue({ singleInstallment: newSingleInstallmentValue }));
      dispatch(setFinalValue({ yearlyPayment: newYearlyPayment }));
    }
  }, [intake, intakeMonthlyInstallments, inferIntakeMonthlyInstallments]);
}

export default useCalculateSimulation;
