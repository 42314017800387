// eslint-disable-next-line
import React, { useState, lazy, Suspense } from 'react';
import { B4U_INSTAGRAM_URL, B4U_FACEBOOK_URL, B4U_LINKEDIN_URL } from '@constants/enviroment';
// @ts-ignore
import { FaLinkedin } from 'react-icons/fa';
import { ReactComponent as Facebook } from '@assets/Icons/facebook.svg';
import { ReactComponent as Instagram } from '@assets/Icons/instagram.svg';
import { useHideHeaderFooter } from '@hooks';
import {
  FooterBox, Left, Right, SocialLinks, Infos,
} from './styles';

const LazyModalTerms = React.lazy(() => import('../Terms/Modals/ModalTerms'));

const Footer: React.FC = () => {
  const { status } = useHideHeaderFooter();
  const [showModalTerms, setShowModalTerms] = useState(false);
  const currentYear = new Date().getFullYear();

  if (status) {
    return null;
  }

  return (
    <>
      {showModalTerms && (
        <React.Suspense fallback={<></>}>
          <LazyModalTerms onClose={setShowModalTerms} />
        </React.Suspense>
      )}

      <FooterBox>
        <Left>
          <span>
            {currentYear}
            {' '}
            ImovClick. Todos os direitos reservados.
          </span>
        </Left>
        <Right>
          <Infos>
            <span onClick={() => setShowModalTerms(true)}>
              Termos e Condições
            </span>
            <span> | </span>
            <span onClick={() => window.location.replace('/faleconosco')}>
              Fale Conosco
            </span>
          </Infos>
          <SocialLinks>
            <FaLinkedin color="white" size={20} onClick={() => window.open(B4U_LINKEDIN_URL)} />
            <Facebook onClick={() => window.open(B4U_FACEBOOK_URL)} />
            <Instagram onClick={() => window.open(B4U_INSTAGRAM_URL)} />
          </SocialLinks>
        </Right>
      </FooterBox>
    </>
  );
};

export default Footer;
