import './App.less';
import './assets/Icons/Icons';

// eslint-disable-next-line
import React, {Suspense} from 'react';

import { Provider } from 'react-redux';
import TitleComponent from '@components/Title';
import Footer from '@components/Footer';
import CookieAlert from '@components/CookieAlert';
import myStore from './redux';
import { Container } from './styles/global';
import Routes from './routes';

function App() {
  const store = myStore({});

  return (
    <Provider store={store}>
      <TitleComponent />
      <Container>
        <Suspense fallback={<></>}>
          <Routes />
        </Suspense>
      </Container>
      <Footer />
      <CookieAlert />
    </Provider>
  );
}

export default App;
