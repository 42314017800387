// eslint-disable-next-line
import React, { useState, lazy, Suspense } from 'react';
import { Container, Text, Button } from './styles';

const ModalTerms = lazy(() => import('../Terms/Modals/ModalTerms'));

function CookieAlert() {
  const [show, setShow] = useState(true);
  const [showModalTerms, setShowModalTerms] = useState(false);

  return (
    <Suspense fallback={<></>}>
      {showModalTerms && <ModalTerms onClose={setShowModalTerms} />}
      {
      show && (
        <Container>
          <Text>
            Utilizamos cookies para garantir a melhor experiência de navegação em nosso site. Para saber mais, leia nossa
            {' '}
            <i
              style={{
                fontStyle: 'normal', textDecoration: 'underline', textDecorationColor: '#fff', cursor: 'pointer',
              }}
              onClick={() => setShowModalTerms(true)}
            >
              Política de Privacidade.
            </i>
          </Text>
          <Button onClick={() => setShow(false)}>ACEITAR</Button>
        </Container>
      )
    }
    </Suspense>
  );
}

export default CookieAlert;
