// eslint-disable-next-line
import React, { useEffect, useState, lazy, Suspense } from 'react';
// @ts-ignore
import Button from '@components/Button';
import Input from '@components/Input';
import RememberMe from '@components/RememberMe';
import LoginWith from '@components/LoginWith';

import FloatingAlertMessage from '@components/FloatingAlertMessage';

import { useModal, useYupResolver } from '@hooks';

import IStore from 'types/Store';
import { ISignin } from 'types/SigninFormData';

import { validateSigninSchema } from '@validation';

import { useForm, FormProvider } from 'react-hook-form';
import { connect, useSelector } from 'react-redux';
import {
  getUser,
  signInUser,
  resetSignInState,
  resetPasswordState,
} from '@actions/auth';
import AlertMessage from '@components/AlertMessage';
import Heading from '@components/Heading';
import B4ULogo from '@assets/Logo/LogoImovClick.svg';
import { loginWithFacebook, loginWithGoogle } from '@services/auth';

import { MdOutlineMailOutline } from 'react-icons/md';
import { FaLock } from 'react-icons/fa';
import {
  Form,
  Logo,
  Header,
  Container,
  DefaultSignup,
  NotHaveAccount,
  SocialMediaLogin,
  RememberMeContainer,
} from './styles';

function Signin({
  signIn,
  getUser,
  resetPasswordState,
  resetSignInState,
}: any) {
  const Signup = lazy(() => import('@pages/Signup'));
  const ForgotPassword = lazy(() => import('@pages/ForgotPassword'));

  const { show, hide } = useModal();
  const { loading, success, error } = useSelector(
    ({ auth: { signIn } }: IStore) => signIn,
  );
  const [socialMediaLoginError, setSocialMediaLoginError] = useState('');
  const methods = useForm<ISignin>({
    mode: 'onChange',
    criteriaMode: 'all',
    resolver: useYupResolver('warn', validateSigninSchema),
    shouldFocusError: true,
    defaultValues: {
      email: '',
      password: '',
      rememberMe: false,
    },
  });

  const {
    handleSubmit, setError, errors, clearErrors,
  } = methods;
  const errorsJson = JSON.stringify(errors);

  useEffect(() => {
    if (error?.includes('user-not-found')) {
      setError('email', { type: 'error', message: 'Email não cadastrado' });
      clearErrors('password');
    }

    if (error?.includes('wrong-password')) {
      setError('password', {
        type: 'error',
        message: 'Senha incorreta',
      });
      clearErrors('email');
    }
  }, [error, setError, errorsJson, clearErrors]);

  useEffect(() => {
    if (resetPasswordState) {
      resetPasswordState();
    }
    return () => {
      if (resetSignInState) {
        resetSignInState();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function facebookLogin() {
    const { success, message, data } = await loginWithFacebook();
    if (success) {
      hide();
      await getUser(data);
    } else if (message?.includes('auth/account-exists-with-different-credential')) {
      setSocialMediaLoginError('Este email já está em uso.');
    }
  }

  async function googleLogin() {
    const { success, message, data } = await loginWithGoogle();
    if (success) {
      hide();
      await getUser(data);
    } else if (message?.includes('auth/account-exists-with-different-credential')) {
      setSocialMediaLoginError('Este email já está em uso.');
    }
  }

  function onSubmit(data: ISignin) {
    signIn(data);
  }

  if (success) {
    hide();
  }

  return (
    <FormProvider {...methods}>
      <Container>
        <Header>
          <Logo>
            <img src={B4ULogo} alt="Logo Build4You" />
          </Logo>
          <Heading type="h5">Faça login para continuar</Heading>
        </Header>
        {error?.includes('too-many-requests') && (
          <AlertMessage
            message="Acesso á este email foi temporariamente disabilitado por causa de várias tentativas. Tente novamente mais tarde"
          />
        )}
        <FloatingAlertMessage
          show={loading}
          message="Solicitação em andamento..."
        />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Input
            prefixIcon={<MdOutlineMailOutline size={18} />}
            name="email"
            placeholder="Email"
          />
          <Input.Password
            prefixIcon={<FaLock name="lock" size={18} />}
            placeholder="Senha"
            name="password"
          />
          <RememberMeContainer>
            <RememberMe />
            <Suspense fallback={<></>}>
              <a
                href="_blank"
                onClick={(e) => {
                  e.preventDefault();
                  show(ForgotPassword, {
                    headerText: 'Esqueceu sua senha?',
                    onGoBack: () => show(Signin, {
                      headerText: 'Faça login para continuar',
                      hideBackButton: true,
                    }),
                  });
                }}
              >
                Esqueci minha senha
              </a>
            </Suspense>
          </RememberMeContainer>
          <Button state={loading ? 'pressed' : 'active'} type="submit">
            ENTRAR
          </Button>
        </Form>
        <NotHaveAccount>
          <SocialMediaLogin>
            <label>
              <span>ou</span>
            </label>
            {socialMediaLoginError && (
              <AlertMessage message={socialMediaLoginError} />
            )}
            <LoginWith
              onClickFacebook={() => facebookLogin()}
              onClickGoogle={() => googleLogin()}
            />
          </SocialMediaLogin>
          <DefaultSignup>
            <label>
              <span>Ainda não tem uma conta?</span>
            </label>

            <Suspense fallback={<></>}>
              <Button
                onClick={() => {
                  show(Signup, {
                    headerText: 'Crie seu cadastro para continuar',
                    onGoBack: () => show(Signin, {
                      headerText: 'Faça login para continuar',
                      hideBackButton: true,
                    }),
                  });
                }}
                type="button"
                buttonClass="outlined"
              >
                CADASTRE-SE
              </Button>
            </Suspense>
          </DefaultSignup>
        </NotHaveAccount>
      </Container>
    </FormProvider>
  );
}

const mapDispatchToProps = (dispatch: any) => ({
  signIn: (user: ISignin) => dispatch(signInUser(user)),
  getUser: () => dispatch(getUser()),
  resetPasswordState: () => dispatch(resetPasswordState()),
  resetSignInState: () => dispatch(resetSignInState()),
});

export default connect(null, mapDispatchToProps)(Signin);
