import routes from '@constants/routes';
import { useRouteMatch } from 'react-router-dom';

const useBreadcrumb = () => {
  const matchRoute = useRouteMatch();

  const crumbs = routes
    .filter(({ path }) => matchRoute.path?.includes(path))
    .map(({ path, ...rest }) => ({
      path: Object.keys(matchRoute.params).length
        ? Object.keys(matchRoute.params).reduce(
          // @ts-ignore: Unreachable code error
          (pathToReplace, param) => pathToReplace.replace(`:${param}`, matchRoute.params[param]),
          path,
        )
        : path,
      ...rest,
    }));

  return { crumbs };
};

export default useBreadcrumb;
