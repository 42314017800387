// @ts-ignore
import { createSlice } from '@reduxjs/toolkit';

const contactUsSlice = createSlice({
  name: 'contactUs',
  initialState: {
    sendingEmail: false,
    success: false,
    completed: false,
    error: null,
  },
  reducers: {
    sendEmailReducer(state) {
      state.sendingEmail = true;
    },
    sendEmailErrorReducer(state, action) {
      state.sendingEmail = false;
      state.success = false;
      state.completed = true;
      state.error = action.payload;
    },
    sendEmailSuccessReducer(state) {
      state.sendingEmail = false;
      state.success = true;
      state.completed = true;
      state.error = null;
    },
    sendEmailResetReducer(state) {
      state.sendingEmail = false;
      state.success = false;
      state.completed = false;
      state.error = null;
    },
  },
});

export const {
  sendEmailReducer,
  sendEmailErrorReducer,
  sendEmailSuccessReducer,
  sendEmailResetReducer,
} = contactUsSlice.actions;

export default contactUsSlice.reducer;
