import colors from '@colors';
import styled from 'styled-components';

export const DefaultButton = styled.button<any>`
  text-transform: uppercase;
  cursor: pointer;
  ${({ width, height }) => `
      width: ${width};
      min-height: ${height};
    `}

  ${({ state, color }) => {
    if (state === 'pressed') {
      return `
      pointer-events: none;
      background-color: ${colors.secondary};
    `;
    }
    return `background-color: ${color};`;
  }}
  border-radius: 24px;
  color: white;
  cursor: pointer;
  border: none;
  &:hover {
    background-color: ${colors.defaultHover};
  }
  &:active {
    background-color: ${colors.secondary};
  }

  ${({ buttonClass }) => {
    if (buttonClass === 'outlined') {
      return `
        color: ${colors.primary};
        border-radius: 24px;
        border: solid 1px ${colors.primary};
        background-color: white;
        &:hover {
          background-color: ${colors.foreground};
        }
        &:active {
          background-color: ${colors.foreground};
        }
      `;
    }

    if (buttonClass === 'flat') {
      return `
        padding: 3px;
        color: ${colors.primary};
        border-radius: 24px;
        border: unset;
        background-color: rgba(0,0,0,0);
        &:hover {
          background-color: ${colors.foreground};
        }
        &:active {
          background-color: ${colors.foreground};
        }
      `;
    }

    if (buttonClass === 'third-button') {
      return `
        padding: 3px;
        color: ${colors.white};
        border-radius: 24px;
        border: unset;
        background-color: ${colors.textMediumEmphasis};
        &:hover {
          opacity: .7
        }
        &:active {
          opacity: .7
        }
      `;
    }
  }}

  ${({ disabled }) => {
    if (disabled) {
      return `
        color: white;
        opacity: 0.3;
        border-radius: 24px;
        border: solid 1px ${colors.foreground} !important;
        background-color: ${colors.textMediumEmphasis};
        
        &:hover {
          border: solid 1px ${colors.foreground} !important;
          background-color: ${colors.textMediumEmphasis};
          cursor: no-drop;
        }
        
      `;
    }
  }}

  @media (max-width: 480px) {
    width: 100%;
  }
`;
