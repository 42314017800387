// eslint-disable-next-line
import React from 'react';
import { showModal, hideModal } from '@actions/modal';
import { useDispatch } from 'react-redux';
import { IObject } from 'types/GenericObject';

export default () => {
  const dispatch = useDispatch();

  function show(component: React.ComponentType, modalProps: IObject) {
    dispatch(showModal({ component, modalProps }));
  }

  function hide() {
    dispatch(hideModal());
  }

  return { show, hide };
};
