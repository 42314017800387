import { Skeleton as SkeletonImage } from '@components/SkeletonImage/styles';
import { Skeleton } from 'antd';
import styled from 'styled-components';

export const CardSkeletonHeading = styled(Skeleton.Input)`
  width: 200px;
`;

export const SkeletonCardBody = styled(Skeleton.Button)`
  border-radius: 24px;
  height: 100% !important;
  span {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    display: block !important;
    width: 100% !important;
    height: 100% !important;
  }
`;

export const SkeletonCardHeader = styled.div`
  & > ${SkeletonImage} {
    height: 100%;
  }
`;

export const Container = styled.div`
  margin-right: 10px;
  margin-bottom: 30px;
  position: relative;
  width: 420px;
  height: 464px;
  display: flex;
  flex-direction: column;
`;

export const CardLoadingSkeleton = styled.div`
  display: flex;
  flex-direction: row;

`;
