// eslint-disable-next-line
import React from 'react';
// @ts-ignore
import { AntDesign } from 'react-web-vector-icons';
import { IoIosCloseCircleOutline } from 'react-icons/io';

import IModal from 'types/Modal';
import { isMobile } from 'react-device-detect';
import { DefaultModal, ModalGoBackButton } from './styles';

function Modal({
  children,
  visible,
  hideBackButton,
  hideCloseButton,
  onGoBack,
  onClose,
  renderModal,
  width,
}: IModal): any {
  function CloseModalIcon() {
    if (hideCloseButton) {
      return null;
    }
    return (
      <ModalGoBackButton onClick={onClose}>
        <IoIosCloseCircleOutline fill="black" size="1.5em" />
      </ModalGoBackButton>
    );
  }

  function ModalHeader() {
    if (hideBackButton) {
      return null;
    }
    return (
      <ModalGoBackButton onClick={onGoBack}>
        <AntDesign name="arrowleft" color="black" size="1.4em" />
      </ModalGoBackButton>
    );
  }

  if (!renderModal) {
    return children;
  }

  return (
    <DefaultModal
      isMobile={isMobile}
      width={width}
      closeIcon={<CloseModalIcon />}
      title={<ModalHeader />}
      footer={null}
      visible={visible}
    >
      {children}
    </DefaultModal>
  );
}

Modal.defaultProps = {
  children: null,
  goBack: null,
  visible: true,
  hideBackButton: false,
  hideCloseButton: false,
  renderModal: true,
};

export default Modal;
