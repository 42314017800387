// eslint-disable-next-line
import React, { useEffect, lazy, Suspense } from 'react';

import { Card as Cards, Avatar, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Video } from '@assets/Icons/video.svg';
import { ReactComponent as Pic } from '@assets/Icons/pic.svg';
import { ReactComponent as ThreeDimension } from '@assets/Icons/3d.svg';
import { ReactComponent as FavTrue } from '@assets/Icons/fav-true.svg';
import { ReactComponent as FavFalse } from '@assets/Icons/fav-false.svg';

import { formatNumberToCurrency } from '@util';
import { useFavorites } from '@hooks';

import {
  CardBox,
  Cost,
  Details,
  CardOptions,
  Description,
  ImageContainer,
  PreLaunch,
} from './styles';

const SquidexImage = lazy(() => import('@components/SquidexImage'));

const { Meta } = Cards;
interface Props {
  id: string;
  slug: string;
  nome: string;
  descricao: string;
  cidade: string;
  bairro: string;
  numero: number;
  rua: string;
  imagemPreview: string;
  imagemCard: string[];
  galeriaFotos: string[];
  videosApresentacao: string[];
  linksTourVirtual: string[];
  precoInicial: number;
  preLancamento: boolean;
  imageWidth: number;
  imagePreload?: boolean;
}

function Card({
  id,
  slug,
  rua,
  nome,
  cidade,
  bairro,
  numero,
  descricao,
  precoInicial,
  galeriaFotos,
  imagemPreview,
  linksTourVirtual,
  videosApresentacao,
  preLancamento,
  imagePreload,
}: Props) {
  const history = useHistory();
  const { favs, addedFav, addOrRemoveFav } = useFavorites();

  useEffect(() => {
    if (addedFav) {
      notification.info({
        message: 'Empreendimento Salvo!',
        icon: <FavTrue />,
        description: (
          <p>
            Para ver seus empreendimentos salvos, acesse a opção
            {' '}
            <a
              href="__blank"
              onClick={(e) => {
                e.preventDefault();
                window.location.replace('/favoritos');
              }}
            >
              Favoritos
            </a>
            {' '}
            no menu Minha Conta
          </p>
        ),
      });
    }
  }, [addedFav]);

  return (
    <CardBox>
      <Cards
        onClick={() => {
          history.push({
            pathname: `/empreendimento/${slug}`,
            state: {
              id,
              cardImg: imagemPreview,
            },
          });
        }}
        title={(
          <div>
            {Boolean(videosApresentacao?.length) && (
              <Avatar shape="square" icon={<Video />} />
            )}
            {Boolean(galeriaFotos?.length) && (
              <Avatar shape="square" icon={<Pic />} />
            )}
            {Boolean(linksTourVirtual?.length) && (
              <Avatar shape="square" icon={<ThreeDimension />} />
            )}
          </div>
        )}
        extra={(
          <div
            onClick={(e) => {
              addOrRemoveFav(id);
              e.stopPropagation();
            }}
          >
            <Avatar
              shape="circle"
              icon={favs?.includes(id) ? <FavTrue /> : <FavFalse />}
            />
          </div>
        )}
        cover={(
          <ImageContainer>
            <Suspense fallback={<></>}>
              <SquidexImage id={imagemPreview} width={420} preload={imagePreload} />
            </Suspense>
          </ImageContainer>
        )}
      >
        <div title={`${rua}, ${numero || 'sem nº'} | ${bairro} - ${cidade}`}>
          <Meta
            title={nome}
            description={`${rua}, ${
              numero || 'sem nº'
            } | ${bairro} - ${cidade}`}
          />
        </div>
        <Description>{descricao}</Description>
        <CardOptions>
          {preLancamento && (
            <PreLaunch>
              <span>PRÉ - LANÇAMENTO</span>
            </PreLaunch>
          )}
          {precoInicial !== 0 && !preLancamento && (
            <Cost>
              A partir de
              {' '}
              <strong>{formatNumberToCurrency(precoInicial)}</strong>
            </Cost>
          )}
          <Details>
            <strong>
              <span
                onClick={() => {
                  history.push({ pathname: `/empreendimento/${slug}` });
                }}
              >
                VER DETALHES
              </span>
            </strong>
          </Details>
        </CardOptions>
      </Cards>
    </CardBox>
  );
}

export default Card;
