import { getStatesCitiesService } from '@services/firebase';
import { useEffect, useState } from 'react';
import IStatesCities from 'types/StatesCities';

export default function useRegister() {
  const [statesCities, setStatesCities] = useState<IStatesCities[]>([]);

  useEffect(() => {
    async function getStatesCities() {
      const { success, data } = await getStatesCitiesService();

      if (success) {
        setStatesCities(data);
      }
    }
    getStatesCities();
  }, []);

  return { statesCities };
}
