// eslint-disable-next-line
import React from 'react';
import Helmet from 'react-helmet';

interface Props {
  title?: string;
  description?: string;
}

const TitleComponent = ({ title, description }: Props) => {
  const defaultTitle = 'ImovClick - Seu empreendimento dos sonhos está aqui';
  return (
    <>
      <Helmet>
        <title>{title || defaultTitle}</title>
        <meta property="og:title" key="og:title" content={title} />
        <meta httpEquiv="Cache-Control" content="public, max-age=31536000" />
      </Helmet>

      {description && (
        <Helmet>
          <meta name="description" content={description} />
          <meta property="og:description" key="og:description" content={description} />
        </Helmet>
      )}
    </>
  );
};

export default TitleComponent;
