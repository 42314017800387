import jsonToFormData from 'json-form-data';
import { apiService, apiServiceWithUserAuthenticated } from '@services/API';

function orderSimulationsDesc(simulations: any) {
  const simulationsReOrder = simulations.reverse() || [];
  return simulationsReOrder;
}

export async function getFirestoreUserService() {
  try {
    const response = await apiServiceWithUserAuthenticated.get('/firebase/user');
    response.data.data.simulations = orderSimulationsDesc(response.data.data.simulations);
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: null,
      error: e,
    };
  }
}

function copyObjectWithExceptions<T extends Record<string, any>>(
  obj: T,
  excludedAttributes: Array<keyof T>,
): Partial<T> {
  const newObj: Partial<T> = {};
  // eslint-disable-next-line
  for (const key in obj) {
    if (!excludedAttributes.includes(key)) {
      newObj[key] = obj[key];
    }
  }

  return newObj;
}

export async function saveUserSimulation(simulationData: any) {
  try {
    // removendo salvamento de todos os imóveis do empreendimento
    const sanitizedRealEstateData = { ...simulationData.realEstateData };

    const sanitizedSimulationData = copyObjectWithExceptions(simulationData, ['properties', 'realEstateData']);
    const sanitizedRealEstateDataNew = copyObjectWithExceptions(sanitizedRealEstateData, ['imoveis']);

    sanitizedSimulationData.realEstateData = sanitizedRealEstateDataNew;

    const response = await apiServiceWithUserAuthenticated.post(
      '/firebase/user/simulations',
      sanitizedSimulationData,
    );

    return response.data;
  } catch (e) {
    return {
      success: false,
      data: null,
      error: e,
    };
  }
}

export async function getStatesCitiesService() {
  try {
    const response = await apiService.get('/firebase/states');

    return response.data;
  } catch (e) {
    return {
      success: false,
      data: null,
      error: e,
    };
  }
}

export async function saveUserFav(id: string) {
  try {
    const response = await apiServiceWithUserAuthenticated.post('/firebase/user/favorites', { id });
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: null,
      error: e,
    };
  }
}

export const getAllFavs = async () => {
  try {
    const response = await apiServiceWithUserAuthenticated.get('/firebase/user/favorites');
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: null,
      error: e,
    };
  }
};

export async function saveUserExclusives(id: string, name: string = '') {
  try {
    const response = await apiServiceWithUserAuthenticated.post('/firebase/user/exclusives', { id, name });

    return response.data;
  } catch (e) {
    return {
      success: false,
      data: null,
      error: e,
    };
  }
}

export async function getAllExclusives() {
  try {
    const response = await apiServiceWithUserAuthenticated.get('/firebase/user/exclusives');
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: null,
      error: e,
    };
  }
}

export async function saveUserRegister(data: any) {
  try {
    const response = await apiServiceWithUserAuthenticated.post('/firebase/user/saveUser', data);
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: null,
      error: e,
    };
  }
}

export async function getStorageFiles() {
  try {
    const response = await apiServiceWithUserAuthenticated.get('/storage/');
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: null,
      error: e,
    };
  }
}

export async function uploadStorageFiles(files: File[]) {
  try {
    const formData = jsonToFormData({
      files,
    });
    const response = await apiServiceWithUserAuthenticated.post('/storage/upload', formData);
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: null,
      error: e,
    };
  }
}
