import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  label:last-child {
    margin-left: 10px;
    margin-bottom: 0;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;
