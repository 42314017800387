import { DefaultSkeleton } from '@globalStyles';
import styled, { keyframes } from 'styled-components';

export const SkeletonAnimation = keyframes`
  0% {
    background-position: -100px;
  }
  100%   {
    background-position: 270px;
  }
    
`;

export const Skeleton = styled.div<any>`
  width: 100%;
  height: 100%;
  min-height: ${({ customSkeletonHeight }) => (customSkeletonHeight ? `${customSkeletonHeight}px` : '200px')};
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    -webkit-text-stroke: 0;
  }
  ${DefaultSkeleton}
`;
