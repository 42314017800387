import moment from 'moment';

const formatDate = (objDate: any) => {
  return moment(objDate).format('DD/MM/YYYY');
};

const formatNumberToCurrency = (number: number) => {
  if (!number) return '';
  return number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
};

const monthDiff = (date1: Date, date2: Date) => {
  let months;
  months = (date2.getFullYear() - date1.getFullYear()) * 12;
  months -= date1.getMonth();
  months += date2.getMonth();
  return months <= 0 ? 0 : months;
};

const quantidadeSemestres = (date1: Date, date2: Date) => {
  const semestres = Math.round(monthDiff(date1, date2) / 6);
  return semestres;
};

const yearDiff = (date1: Date, date2: Date) => {
  const years = date2.getFullYear() - date1.getFullYear();
  return years <= 0 ? 0 : years;
};

const validate18Years = (date: string) => {
  const momentDate = moment(date, 'DDMMYYYY');
  const now = moment();
  const diff = now.diff(momentDate, 'years');
  return diff >= 18;
};

const validateWeddingDate = (date: string) => {
  const momentDate = moment(date, 'DDMMYYYY');
  const now = moment();
  const diff = now.diff(momentDate, 'days');
  return diff >= 1;
};

const numberByPercentage = (value: number, percentage: number) => {
  return (percentage / 100) * value;
};

const discount = (value: number, discountValue: number) => {
  const newValue = value - (discountValue / 100) * value;
  if (newValue > 0) {
    return newValue;
  }
  return value;
};

const currencyStringToFloat = (value: string) => {
  return parseFloat(value.replace(/\./g, '').replace(/,/g, '.'));
};

const scrollToTop = (e: any) => {
  e.preventDefault();
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export {
  formatDate,
  formatNumberToCurrency,
  monthDiff,
  quantidadeSemestres,
  yearDiff,
  validate18Years,
  validateWeddingDate,
  numberByPercentage,
  discount,
  currencyStringToFloat,
  scrollToTop,
};
