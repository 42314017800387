import colors from '@colors';
import styled from 'styled-components';

export const Button = styled.button`
  border-radius: 100%;
  border: none;
  width: 68px;
  height: 68px;
  background: white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1),
    0px 0px 10px rgba(203, 186, 252, 0.15),
    0px 1px 11px rgba(139, 106, 236, 0.2);
  cursor: pointer;
  outline: none;
  & > :first-child {
    color: ${colors.primary} !important;
  }
  &:hover {
    background: ${colors.primary};
    & > :first-child {
      color: white !important;
    }
  }
`;
