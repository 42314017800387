import {
  BREADCRUMB,
  RESET_SIMULATOR,
  NEXT_STEP,
  SET_CURRENT_VALUE,
  PREPARE_NEXT_STEP,
  PREVIOUS_STEP,
  PREPARE_PREV_STEP,
  INIT_SIMULATION,
  INIT_SIMULATION_SUCCESS,
  FILL_REST,
  INIT_SIMULATION_ERROR,
  JUMP_STEP,
  PREPARE_JUMP_STEP,
  DISCOUNT_1,
  DISCOUNT_2,
  DISCOUNT_3,
  FINISH_SIMULATION,
  SEND_PROPOSAL,
  LOAD_SIMULATION,
  SAVE_SIMULATION,
  REMOVE_DISCOUNT,
  EDIT_INTAKE,
  SET_CURRENT_BREADCRUMB,
  EDIT_SINGLE_INSTALLMENT,
  SET_FINAL_SUMMARY_VALUES,
  SET_INTAKE_INSTALLMENTS,
  EDIT_MONTHLY_INSTALLMENT,
  FINISH_SIMULATION_SUCCESS,
  EDIT_YEARLY_OR_SEMIANNUAL,
  SET_FINAL_SUMMARY_CALCULUS,
  JUMP_AND_ACCEPT_SUGGESTIONS,
  JUMP_AND_PAY_IN_CASH,
  EDIT_IS_YEARLY_OR_SEMIANNUAL_PAYMENT,
  UPDATE_DISCOUNT,
} from '@reducers/simulator';
import {
  getRealEstateProperties,
  sendProposalService,
} from '@services/squidex';

const initSimulator = (id: string) => async (dispatch: any) => {
  dispatch(INIT_SIMULATION());
  const { success, data, message } = await getRealEstateProperties(id);
  if (success) {
    dispatch(INIT_SIMULATION_SUCCESS({ ...data, id }));
    dispatch(BREADCRUMB(data.nome));
    dispatch(BREADCRUMB('Simulador'));
  } else {
    dispatch(INIT_SIMULATION_ERROR(message));
  }
};

const loadSavedSimulation = (simulationData: any) => LOAD_SIMULATION(simulationData);

const jumpAndAcceptSuggestions = () => JUMP_AND_ACCEPT_SUGGESTIONS();

const jumpAndPayInCash = () => JUMP_AND_PAY_IN_CASH();

const saveSimulation = () => SAVE_SIMULATION();

const finishSimulation = () => FINISH_SIMULATION();

const finishSimulationSuccess = () => FINISH_SIMULATION_SUCCESS();

const sendProposal = ({
  id,
  name,
  cardImage,
  ...rest
}: {
    id: string;
    cardImage: string;
    name: string;
  }) => async (dispatch: any) => {
  await sendProposalService({
    id,
    name,
    cardImage,
    ...rest,
  });
  dispatch(SEND_PROPOSAL());
};

const resetSimulator = (id: string) => async (dispatch: any) => {
  dispatch(RESET_SIMULATOR());
  dispatch(initSimulator(id));
};

const setBreadCrumb = (newCrumb: string) => BREADCRUMB(newCrumb);

const setClickedBreadcrumb = (newCurrentCrumb: string) => {
  return SET_CURRENT_BREADCRUMB(newCurrentCrumb);
};

const nextStep = (previousStepData: any) => NEXT_STEP(previousStepData);

const jumpToStep = () => JUMP_STEP();

const previousStep = () => PREVIOUS_STEP();

const setDiscount1 = () => DISCOUNT_1();

const setDiscount2 = () => DISCOUNT_2();

const setDiscount3 = () => DISCOUNT_3();

const removeDiscount = () => REMOVE_DISCOUNT();

const updateDiscount = () => UPDATE_DISCOUNT();

const setFinalCalculus = () => SET_FINAL_SUMMARY_CALCULUS();

const setFinalValue = (newValue: any) => SET_FINAL_SUMMARY_VALUES(newValue);

const setFinalIntakeInstallments = (newValue: any) => {
  return SET_INTAKE_INSTALLMENTS(newValue);
};

const setCurrentValue = (currentValue: any) => SET_CURRENT_VALUE(currentValue);

const prepareJumpToStep = (newStep: number) => PREPARE_JUMP_STEP(newStep);

const prepareNextStep = () => PREPARE_NEXT_STEP();

const preparePreviousStep = () => PREPARE_PREV_STEP();

const fillRest = (selectedProperty: any) => FILL_REST(selectedProperty);

const editIntake = (newIntake: any) => EDIT_INTAKE(newIntake);

const editMonthlyInstallment = (newMonthlyInstallment: any) => {
  return EDIT_MONTHLY_INSTALLMENT(newMonthlyInstallment);
};

const editYearlyOrSemiannualValue = (newYearlyOrSemiannualValue: any) => {
  return EDIT_YEARLY_OR_SEMIANNUAL(newYearlyOrSemiannualValue);
};

const editSingleInstallment = (newSingleInstallment: any) => {
  return EDIT_SINGLE_INSTALLMENT(newSingleInstallment);
};

const editIsYearlyOrSemiannualPayment = (type: any) => {
  return EDIT_IS_YEARLY_OR_SEMIANNUAL_PAYMENT(type);
};

export {
  nextStep,
  fillRest,
  jumpToStep,
  editIntake,
  setDiscount1,
  setDiscount2,
  setDiscount3,
  sendProposal,
  previousStep,
  initSimulator,
  setBreadCrumb,
  setFinalValue,
  setFinalIntakeInstallments,
  saveSimulation,
  resetSimulator,
  removeDiscount,
  updateDiscount,
  prepareNextStep,
  setCurrentValue,
  finishSimulation,
  setFinalCalculus,
  prepareJumpToStep,
  preparePreviousStep,
  loadSavedSimulation,
  setClickedBreadcrumb,
  editSingleInstallment,
  editMonthlyInstallment,
  finishSimulationSuccess,
  jumpAndAcceptSuggestions,
  jumpAndPayInCash,
  editYearlyOrSemiannualValue,
  editIsYearlyOrSemiannualPayment,
};
