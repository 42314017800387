import Heading from 'types/Heading';
import styled from 'styled-components';
import colors from '@colors';

export const DefaultHeading = styled.p<Heading>`
  color: ${colors.textHighEmphasis};
  ${({ type }) => {
    switch (type) {
      case 'h5':
        return `
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
        `;
      case 'h4':
        return `
          font-size: 20px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
        `;
      case 'h3':
        return `
          font-size: 25px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.28;
        `;
      case 'h2':
        return `
          font-size: 30px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.07;
        `;
      case 'h1':
        return `  
          font-size: 35px;
          font-weight: 800;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
        `;
      default:
        return '';
    }
  }}
`;
