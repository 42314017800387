function imgCache(state: any = {}, action: any) {
  switch (action.type) {
    case 'SET_SINGLE_IMG':
      return {
        ...state,
        [action.key]: action.img,
      };

    default:
      return state;
  }
}

export default imgCache;
