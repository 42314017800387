import styled from 'styled-components';
import colors from '@colors';
import { Menu } from 'antd';

export const HideHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 0 -4.55%;
  max-height: 160px;
  background-color: #fbfbfe;
  flex: 1;
`;

export const Container = styled.div`
  position: relative;
  min-height: 70px;
  display: flex;
  z-index: 10;
  align-items: center;
  justify-content: center;
  padding: 40px 4.5% 40px 4.5%;
  z-index: 3;
  background: white;
  width: 109%;
  left: -4.5%;
  li.ant-menu-item.ant-menu-item-only-child {
    font-size: 16px;
    -webkit-text-stroke: 0;
    padding: 0;
    :last-child {
      display: none;
      @media only screen and (max-device-width: 480px) {
        display: block;
      }
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LogoBox = styled.div`
  cursor: pointer;

  img {
    width: 198px;
    height: auto;
  }
`;

export const MenuOverlayContainer = styled.div`
  border-radius: 24px;
  box-shadow: 0 1px 5px 0 rgba(139, 106, 236, 0.2);
  .ant-menu {
    border-radius: 24px;
  }
  .ant-divider.ant-divider-horizontal {
    margin: 0;
  }
  .ant-menu-item {
    display: flex;
    align-items: center;
    svg {
      width: 17px;
      height: 18px;
      path {
        fill: ${colors.textMediumEmphasis} !important;
      }
    }
    :hover {
      span {
        color: ${colors.primary};
      }
      svg {
        path {
          fill: ${colors.primary} !important;
        }
      }
    }

    svg {
      margin-right: 10px;
    }
  }
`;

export const MenuBox = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
  .ant-menu-horizontal {
    display: flex;
    align-items: center;
    border-bottom: none;
    flex-wrap: wrap;
  }

  li.ant-menu-item.ant-menu-item-only-child {
    font-family: "Onest", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    text-align: right;
    color: ${colors.textHighEmphasis};
  }
`;

export const CustomMenu = styled(Menu)`
  .ant-divider.ant-divider-horizontal {
    margin: 0;
  }
  position: absolute;
  top: 125px;
  left: 0;
  width: 100%;
  border-right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-device-width: 480px) {
    align-items: flex-start;
  }
  li {
    display: flex;
    align-items: center;
    span {
      font-size: 16px;
      -webkit-text-stroke: 0;
    }
    @media only screen and (max-device-width: 480px) {
      margin-left: 25%;
    }
    svg {
      margin-right: 4px;
    }
  }
`;

export const LoginBox = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
  .ant-btn {
    border: solid 1px;
    border-color: ${colors.primary};
    flex-direction: column;
    align-items: center;
    padding: 10px 0px 10px 0px;
    position: relative;
    width: 170px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 24px;
    font-family: Onest;
    font-size: 14px;
    line-height: 1.43;
    text-align: center;
  }

  .anticon svg {
    color: ${colors.primary};
  }

  .ant-btn > span {
    color: ${colors.primary};
  }

  .ant-btn > span:hover {
    color: ${colors.primary};
  }

  .ant-btn:hover,
  .ant-btn:focus {
    background-color: ${colors.foreground};
    border-color: ${colors.primary};
  }

  svg {
    color: ${colors.textMediumEmphasis};
  }

  button.ant-btn.ant-dropdown-trigger {
    text-align: left !important;
  }
`;

export const LoginSection = styled.div`
  display: block;
`;

export const HamburgerMenuContainer = styled.div`
  display: none;
  @media (max-width: 1024px) {
    li {
      svg {
        width: 17px;
        height: 18px;
        path {
          fill: ${colors.textMediumEmphasis} !important;
        }
      }
    }
    ${LoginSection} {
      display: none;
      @media only screen and (max-device-width: 480px) {
        display: block;
      }
    }
    ${LoginBox} {
      display: block;
      @media only screen and (max-device-width: 480px) {
        display: none;
      }
    }
    .ant-divider.ant-divider-horizontal {
      display: none;
      @media (max-width: 1024px) {
        display: block;
      }
    }
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-grow: 0.1;
    i {
      color: black !important;
    }
  }
  @media (max-width: 480px) {
    flex-grow: 0;
  }
`;

export const Overlay = styled.div`
  @media (max-width: 1024px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${colors.textHighEmphasis};
    opacity: 0.4;
    z-index: 2;
  }
`;

export const UserLoggedContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  flex: 1;
  justify-content: space-evenly;
  width: 100%;
  li:not(:last-child) {
    margin-bottom: 30px;
  }
  @media (max-width: 1024px) {
    align-items: center;
  }
  @media (max-width: 480px) {
    align-items: unset;
  }
`;
