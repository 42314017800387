import React from 'react';

import { Action, ThunkAction } from '@reduxjs/toolkit';
import { RootStateOrAny } from 'react-redux';

interface IModalStore {
  component: React.ComponentType;
  modalProps: any;
}

const showModal = ({
  component,
  modalProps,
}: IModalStore): ThunkAction<void, RootStateOrAny, unknown, Action<string>> => (
  dispatch,
) => {
  try {
    dispatch({
      type: 'SHOW_MODAL',
      component: component || null,
      modalProps: {
        ...modalProps,
        onClose: () => dispatch({
          type: 'HIDE_MODAL',
        }),
      },
    });
  } catch (e) {
    console.error(e);
  }
};

const hideModal = () => ({
  type: 'HIDE_MODAL',
});

export { showModal, hideModal };
