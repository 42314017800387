// eslint-disable-next-line
import React, { ReactElement } from 'react';
// @ts-ignore
import { FaImage } from 'react-icons/fa';
import { Skeleton } from './styles';

interface Props {
  animate?: boolean;
  customSkeletonHeight?: number;
}

export default function SkeletonImage({ animate, customSkeletonHeight }: Props): ReactElement {
  return (
    <Skeleton customSkeletonHeight={customSkeletonHeight} animate={animate}>
      <FaImage size={60} color="white" />
    </Skeleton>
  );
}
