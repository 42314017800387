function hideHeaderFooter(state: boolean = false, action: any) {
  switch (action.type) {
    case 'HIDE':
      return false;

    case 'SHOW':
      return true;

    default:
      return state;
  }
}

export default hideHeaderFooter;
