import { useRef, useState } from 'react';

export default function useLottieAnimationPause() {
  const lottieRef = useRef(null);
  const [pauseAnimation, setPauseAnimation] = useState(false);
  function pauseWhenLottieAnimationEnds(check?: boolean) {
    const ANIMATION_TIME_SUBTRAHEND = check === true ? 1 : 20;
    if (lottieRef?.current) {
      const { anim } = lottieRef.current as any;
      if (anim.currentFrame > anim.totalFrames - ANIMATION_TIME_SUBTRAHEND) {
        setPauseAnimation(true);
      }
    }
  }

  return {
    lottieRef,
    pauseAnimation,
    pauseWhenLottieAnimationEnds,
  };
}
