// eslint-disable-next-line
import React, { ReactElement, lazy, Suspense } from 'react';
import { Spin } from 'antd';
import colors from '@colors';

interface Props {
  size: number;
  color?: string;
}

const LoadingOutlined = lazy(() => import('@ant-design/icons')
  .then((module) => ({ default: module.LoadingOutlined })));

function LoadingSpinner({ size, color }: Props): ReactElement {
  return (
    <Suspense fallback={<></>}>
      <Spin
        indicator={(
          <LoadingOutlined
            style={{ fontSize: size, color: color || colors.primary }}
            spin
          />
        )}
      />
    </Suspense>
  );
}

LoadingSpinner.defaultProps = {
  size: 24,
};

export default LoadingSpinner;
