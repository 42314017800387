import IResponse from 'types/Response';
import jsonToFormData from 'json-form-data';
import { apiService, apiServiceWithUserAuthenticated } from '@services/API';

export async function getAllRealEstate() {
  try {
    const response = await apiService.get('/squidex/realestate');
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: [],
      error: e,
    };
  }
}

export async function uploadReceiptService(
  receiptFile: File,
  name: string,
  id: string,
) {
  try {
    const options = {
      initialFormData: new FormData(),
      showLeafArrayIndexes: true,
      includeNullValues: true,
      mapping(value: any) {
        if (typeof value === 'boolean') {
          return +value ? '1' : '0';
        }
        return value;
      },
    };

    const formData = jsonToFormData(
      {
        receiptFile,
        name,
        id,
      },
      options,
    );
    const response = await apiServiceWithUserAuthenticated.post(
      '/squidex/proposal/contract/receipt/send',
      formData,
    );
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: [],
      message: e,
    };
  }
}

export async function getProposalStatusService({
  id,
  name,
}: {
  id: string;
  name: string;
}): Promise<IResponse> {
  try {
    const response = await apiServiceWithUserAuthenticated.get(
      `/squidex/proposal/?id=${id}&name=${encodeURI(name)}`,
    );
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: [],
      message: e,
    };
  }
}

export async function sendSignatureType({
  signatureType,
}: {
  signatureType: 'PRESENCIAL' | 'DIGITAL' | undefined;
}): Promise<IResponse> {
  try {
    const response = await apiServiceWithUserAuthenticated.get(
      `/squidex/proposal/contract/signature/?signatureType=${signatureType}`,
    );
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: [],
      message: e,
    };
  }
}

export async function sendProposalService({
  id,
  name,
  cardImage,
  ...store
}: {
  id: string;
  name: string;
  cardImage: string;
}) {
  const jsonData: any = {
    id,
    name,
    ...store,
  };
  const blob = await fetch(cardImage).then((res) => res.blob());

  const options = {
    initialFormData: new FormData(),
    showLeafArrayIndexes: true,
    includeNullValues: true,
    mapping(value: any) {
      if (typeof value === 'boolean') {
        return +value ? '1' : '0';
      }
      return value;
    },
  };

  const formData = jsonToFormData(jsonData, options);
  formData.append('cardImage', blob, 'cardImage.png');

  try {
    const response = await apiServiceWithUserAuthenticated.post('squidex/proposal/send', formData);
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: [],
      error: e,
    };
  }
}

export async function getAsset(id: string) {
  try {
    const response = await apiService.get(`/squidex/asset/${id}`, {
      responseType: 'blob',
    });
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: [],
      error: e,
    };
  }
}

export async function getRealEstateById(id: string) {
  try {
    const response = await apiService.get(`/squidex/realestate/id/${id}`);
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: [],
      error: e,
    };
  }
}

export async function getRealEstateBySlug(slug: string) {
  try {
    const response = await apiService.get(`/squidex/realestate/slug/${slug}`);
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: [],
      error: e,
    };
  }
}

export async function getRealEstateProperties(id: string) {
  try {
    const response = await apiService.get(`/squidex/realestate/properties/${id}`);
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: [],
      error: e,
    };
  }
}

export async function filterRealEstates(
  filters: any,
  currentPage: number = 0,
  itemsPerPage: number = 0,
) {
  try {
    const { data: realestate } = await apiService.post('/squidex/realestate', {
      filters,
      currentPage,
      itemsPerPage,
    });

    if (realestate.data.data.length === 0) {
      const { data: realestateDefault } = await apiService.post('/squidex/realestate/default', {
        currentPage,
        itemsPerPage,
      });
      return { ...realestateDefault, recommended: true };
    }
    return { ...realestate, recommended: false };
  } catch (e) {
    return {
      recommended: false,
      success: false,
      data: [],
      error: e,
    };
  }
}

export async function findRealEstatesToAutocomplete(input: string) {
  try {
    const { data: realestates } = await apiService.get(`/squidex/search/generic-search/empreendimento/${input}`);

    if (realestates) {
      return realestates;
    }

    return [];
  } catch (e) {
    return {
      success: false,
      message: e,
      data: null,
    };
  }
}
