import { Button as RoundWhiteButton } from '@components/RoundWhiteButton/styles';
import styled from 'styled-components';
import { Swiper } from 'swiper/react';

export const CarouselContainer = styled.div<any>`
  cursor: grab;
  position: relative;
  margin: 0 -4.55%;  

  .ant-card-extra {
    cursor: pointer;
  }

  @media (max-width: 780px) {
    margin-left: 0px;
  }
`;

export const AbsoluteRoundButtonRight = styled(RoundWhiteButton)<any>`
  @media (max-width: 1024px) {
    display: none;
  }
  ${({ cardWidth }) => `right: ${cardWidth / 7}px;`}
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  z-index: 2;
  ${({ renderRightButton }) => `visibility: ${renderRightButton ? 'visible' : 'hidden'};`}
  i {
    pointer-events: none;
  }
`;

export const AbsoluteRoundButtonLeft = styled(RoundWhiteButton)<any>`
  @media (max-width: 1024px) {
    display: none;
  }
  ${({ cardWidth }) => `left: ${cardWidth / 7}px;`}
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  z-index: 2;
  ${({ renderLeftButton }) => `visibility: ${renderLeftButton ? 'visible' : 'hidden'};`}
  i {
    pointer-events: none;
  }
`;

export const CustomCarousel = styled(Swiper)`
  z-index: 0;
  .swiper-slide {
    width: auto !important;
    padding-top: 15px;
    flex-shrink: unset;
    @media (max-width: 1024px) {
      width: auto !important;
    }
  }
  .swiper-wrapper {
    display: flex;
  }
`;
