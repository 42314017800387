// eslint-disable-next-line
import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Header from '@components/Header';
import Modal from '@components/Modal';
import ScrollOnRouteChange from '@components/ScrollOnRouteChange';
import routes from '@constants/routes';
import PrivateRoute from './PrivateRoute';

const Routes: React.FC = () => {
  const { component: ModalChild, modalProps, show } = useSelector(
    ({ modal }: any) => modal,
  );

  const RoutesComponent = routes.map(({
    path, privateRoute, exact, component, name,
  }: any) => (privateRoute ? (
    <PrivateRoute
      path={path}
      exact={!!exact}
      key={path}
      component={component}
      name={name || ''}
    />
  ) : (
    <Route
      path={path}
      exact={!!exact}
      key={path}
      component={component}
      name={name || ''}
    />
  )));

  return (
    <>
      {(() => {
        if (show) {
          return (
            <Modal {...modalProps}>
              <ModalChild />
            </Modal>
          );
        }
        return null;
      })()}
      <BrowserRouter>
        <ScrollOnRouteChange>
          <Header />
          <Switch>
            {RoutesComponent}
          </Switch>
        </ScrollOnRouteChange>
      </BrowserRouter>
    </>
  );
};

export default Routes;
