// eslint-disable-next-line
import React from 'react';
import { Button } from 'antd';
import { ReactComponent as Facebook } from '@assets/Icons/facebook-color.svg';
import { ReactComponent as Google } from '@assets/Icons/google-color.svg';
import { LoginWithBox } from './styles';

interface Props {
  onClickFacebook?: any;
  onClickGoogle?: any;
}

function LoginWith({ onClickFacebook, onClickGoogle }: Props) {
  return (
    <LoginWithBox>
      <Button onClick={onClickFacebook} icon={<Facebook />}>
        {' '}
        Entrar com Facebook
        {' '}
      </Button>
      <Button onClick={onClickGoogle} icon={<Google />}>
        {' '}
        Entrar com Google
        {' '}
      </Button>
    </LoginWithBox>
  );
}

export default LoginWith;
