import yup from '@lib/yup';

const validateContactUsSchema = yup.object({
  name: yup.string().required(),
  email: yup.string().email().required(),
  message: yup.string().required(),
  phone: yup.string(),
});

export default validateContactUsSchema;
