import { createSlice } from '@reduxjs/toolkit';
import { monthDiff, numberByPercentage, yearDiff } from '@util';

const initialState = {
  step: 0,
  simulationId: '',
  stepsData: {},
  properties: {},
  step1DefaultData: [],
  initialLoading: true,
  breadcrumb: [],
  currentValue: null,
  realEstateData: {},
  nextStepReady: false,
  previousStepReady: false,
  jumpToStepReady: false,
  simulationStarted: false,
  propertySelected: null,
  price: '',
  intake: '',
  balanceDebt: '',
  contribution: '',
  yearlyPayment: '',
  oldYearlyPayment: '',
  oldSingleInstallment: '',
  intakeMonthlyInstallments: 1,
  inferIntakeMonthlyInstallments: 1,
  monthlyPayment: '',
  totalMonthlyPayment: '',
  totalYearlyPayment: '',
  currentBreadcrumb: '',
  receiptFileName: '',
  hasDiscount: false,
  discount: 0,
  signatureType: undefined,
  transitioning: false,
  finalStep: false,
  proposalSend: false,
  simulationSaved: false,
  error: null,
};

function resetReducer() {
  return initialState;
}

function loadSavedSimulation(state: any, action: any) {
  if (action?.payload?.simulationId) {
    return {
      ...state,
      ...action.payload,
      breadcrumb: [
        ...action.payload.breadcrumb.slice(0, -1),
        (action.payload.proposalSend ? 'Proposta ' : 'Simulação ')
        + action.payload.simulationId,
      ],
      currentBreadcrumb: `${state.proposalSend ? 'Proposta' : 'Simulação'} ${action.payload.simulationId
      }`,
    };
  }

  return {
    ...state,
    initialLoading: false,
    error: true,
  };
}

function saveSimulation(state: any) {
  return {
    ...state,
    breadcrumb: [
      'Início',
      'Negociações',
      `${state.proposalSend ? 'Proposta' : 'Simulação'} ${state.simulationId}`,
    ],
    simulationSaved: true,
  };
}

function initSimulation() {
  return {
    ...initialState,
  };
}

function jumpAndAcceptSuggestions(state: any) {
  const { dataEntrega } = state.realEstateData;
  const {
    valorMensais,
    valorAnualSemestral,
    valorParcelaUnica,
    valorEntrada,
    parcelasEntrada,
    valorAporte,
  } = state.propertySelected;

  const totalMonthlyPayment = Math.round(
    parseFloat(
      (
        valorMensais
        * (monthDiff(new Date(), new Date(dataEntrega)) - 1)
      ).toFixed(2),
    ),
  );

  const totalYearlyPayment = Math.round(
    valorAnualSemestral * yearDiff(new Date(), new Date(dataEntrega)),
  );

  return {
    ...state,
    step: 10,
    intake: valorEntrada,
    contribution: valorAporte,
    oldYearlyPayment: valorAnualSemestral,
    totalMonthlyPayment,
    totalYearlyPayment,
    intakeMonthlyInstallments: 1,
    inferIntakeMonthlyInstallments: 1,
    stepsData: {
      ...state.stepsData,
      5: {
        oldValue: {
          intake: valorEntrada,
          willSplitIntake: parcelasEntrada.length > 1 ? 1 : 0,
          installments: parcelasEntrada,
        },
        value: {
          intake: valorEntrada,
          willSplitIntake: parcelasEntrada.length > 1 ? 1 : 0,
          installments: parcelasEntrada,
        },
      },
      6: {
        label: `Mensais ${valorMensais} vencimento no dia 5 de cada mês`,
        oldValue: {
          monthlyInstallment: valorMensais,
          dueDate: {
            label: 'Dia 05 de cada mês',
            value: 5,
          },
        },
        value: {
          monthlyInstallment: valorMensais,
          dueDate: {
            label: 'Dia 05 de cada mês',
            value: 5,
          },
        },
      },
      7: {
        label: 'Anual',
        oldValue: 1,
        value: 1,
      },
      8: {
        label: `Anuais em Fevereiro por ${valorAnualSemestral}`,
        oldValue: {
          yearlyOrSemiannualValue: valorAnualSemestral,
          dueDate: [{ label: 'Janeiro', value: 'Janeiro' }],
        },
        value: {
          yearlyOrSemiannualValue: valorAnualSemestral,
          dueDate: [{ label: 'Janeiro', value: 'Janeiro' }],
        },
      },
      9: {
        label: valorParcelaUnica,
        oldValue: valorParcelaUnica,
        value: valorParcelaUnica,
      },
    },
  };
}

// desconto ao pagar imovel a vista
function discount1(state: any) {
  const { descontoAoPagarImovelAvista } = state.propertySelected;

  const { price } = state;

  if (!descontoAoPagarImovelAvista) return state;

  return {
    ...state,
    hasDiscount: true,
    discount: numberByPercentage(price, descontoAoPagarImovelAvista),
  };
}

function jumpAndPayInCash(state: any) {
  const discountState = discount1(state);

  const { price, discount } = discountState;

  const priceWithDiscount = price - discount;

  return {
    ...discountState,
    step: 10,
    intake: priceWithDiscount,
    contribution: priceWithDiscount,
    totalMonthlyPayment: 0,
    totalYearlyPayment: 0,
    singleInstallment: 0,
    monthlyPayment: 0,
    yearlyPayment: 0,
    stepsData: {
      ...state.stepsData,
      5: {
        oldValue: {
          intake: price,
          willSplitIntake: 0,
          installments: [{ nomeParcela: 'Ato', valorParcela: price }],
        },
        value: {
          intake: price,
          willSplitIntake: 0,
          installments: [{ nomeParcela: 'Ato', valorParcela: priceWithDiscount }],
        },
      },
      6: {
        value: { dueDate: { value: 1 } },
      },
      7: {
        label: '',
        oldValue: 0,
        value: 0,
      },
    },
  };
}

function simulationInitiatedSuccess(state: any, action: any) {
  const { imoveis, possuiTorre } = action.payload;
  const step1DefaultData = [
    ...(new Set(
      imoveis.map(({ data: { torre } }: any) => {
        if (possuiTorre) {
          return JSON.stringify({
            label: `${torre}`,
            value: JSON.stringify({ label: torre, value: torre }),
          });
        }
        return JSON.stringify({
          label: 'Torre Única',
          value: JSON.stringify({ label: 'Torre Única', value: torre }),
        });
      }),
    ) as any),
    // @ts-ignore
  ].map(JSON.parse);
  return {
    ...state,
    simulationId: `B4U${new Date().getTime().toString(36).toUpperCase()}`,
    initialLoading: false,
    realEstateData: action.payload,
    properties: imoveis,
    step1DefaultData,
  };
}

function simulationError(state: any, action: any) {
  return {
    ...state,
    initialLoading: false,
    error: action.payload,
  };
}

function setFinalSummaryCalculus(state: any) {
  const { dataEntrega } = state.realEstateData;
  const {
    valorMensais,
    valorAnualSemestral,
    valorParcelaUnica,
    valorEntrada,
    price,
  } = state.propertySelected;
  const singleInstallment = valorParcelaUnica;
  const intake = valorEntrada;
  const monthlyPayment = valorMensais;
  const totalMonthlyPayment = Math.round(
    parseFloat(
      (
        valorMensais
        * (monthDiff(new Date(), new Date(dataEntrega)) - 1)
      ).toFixed(2),
    ),
  );

  const yearlyPayment = valorAnualSemestral;
  const totalYearlyPayment = Math.round(
    valorAnualSemestral * yearDiff(new Date(), new Date(dataEntrega)),
  );
  const balanceDebt = price;

  const contribution = 0;

  return {
    ...state,
    price,
    intake,
    balanceDebt,
    contribution,
    yearlyPayment,
    monthlyPayment,
    singleInstallment,
    totalYearlyPayment,
    totalMonthlyPayment,
  };
}

function removeDiscount(state: any) {
  return {
    ...state,
    hasDiscount: false,
    discount: 0,
  };
}

// desconto quando paga a entrada a vista
function discount2(state: any) {
  const { descontoEntradaAvista, valorAporte } = state.propertySelected;

  if (!descontoEntradaAvista) return state;

  return {
    ...state,
    hasDiscount: true,
    discount: numberByPercentage(valorAporte, descontoEntradaAvista),
  };
}

// desconto quando ultrapassa o valor do aporte
function discount3(state: any) {
  const { descontoAporteMaior, valorAporte } = state.propertySelected;

  const { intake } = state;

  const price = intake - valorAporte;

  if (!descontoAporteMaior) return state;
  return {
    ...state,
    hasDiscount: true,
    discount: numberByPercentage(price, descontoAporteMaior),
  };
}

function setFinalSummaryValues(state: any, action: any) {
  const [key, value]: any = Object.entries(action.payload)[0];
  let newValue = 0;
  if (value > 0 || typeof value === 'string') {
    newValue = value;
  }
  return {
    ...state,
    [key]: newValue,
  };
}

function setIntakeInstallments(state: any, action: any) {
  const intakeInstallments: {
    nomeParcela: string;
    valorParcela: number;
  }[] = action.payload.intakeInstallmentsArray;
  const validInstallments = intakeInstallments.filter(
    ({ valorParcela }) => valorParcela,
  );
  const inferIntakeMonthlyInstallments = 1;

  /// /////////////////////////////////////// AQUI
  /*
  if (validInstallments.length > 1) {
    const filtered = validInstallments.filter(
      ({ nomeParcela }: { nomeParcela: string }) =>
        nomeParcela.toLowerCase() !== 'ato'
    );
    const highestNumber = filtered
      .map(({ nomeParcela }: { nomeParcela: string }) =>
        parseInt(nomeParcela.split(' ')[0])
      )
      .sort((a, b) => b - a)[0];
    inferIntakeMonthlyInstallments = highestNumber / 30 + 1;

  }
  */

  return {
    ...state,
    intakeMonthlyInstallments: validInstallments.length,
    inferIntakeMonthlyInstallments,
    stepsData: {
      ...state.stepsData,
      5: {
        ...state.stepsData[5],
        oldValue: {
          ...state.stepsData[5].oldValue,
          installments: validInstallments,
        },
        value: { ...state.stepsData[5].value, installments: validInstallments },
      },
    },
  };
}

function setCurrentValueReducer(state: any, action: any) {
  const resetAllOtherData = Object.values(state.stepsData).slice(0, state.step);

  return {
    ...state,
    currentValue: action.payload,
    stepsData: {
      ...{ ...resetAllOtherData },
      [state.step]: action.payload,
    },
    breadcrumb:
      state.step < 4 ? state.breadcrumb.slice(0, 3) : state.breadcrumb,
    propertySelected: state.step < 4 ? null : state.propertySelected,
  };
}

function previousStepReducer(state: any) {
  const previousStep = state.step === 5 && state.currentValue?.value?.willSplitIntake
    ? state.step
    : state.step - 1;
  return {
    ...state,
    step: previousStep,
    currentValue: state.stepsData[previousStep],
    nextStepReady: false,
    previousStepReady: false,
    stepsData: {
      ...state.stepsData,
      [state.step]: state.currentValue || null,
    },
  };
}

function nextStepReducer(state: any, action: any) {
  const nextStep = state.step === 5 && state.currentValue?.value?.willSplitIntake
    ? state.step
    : state.step + 1;

  const nextState = {
    ...state,
    step: nextStep,
    currentValue: state.stepsData[nextStep] || null,
    nextStepReady: false,
    previousStepReady: false,
    stepsData: {
      ...state.stepsData,
      [state.step]: action.payload || state.stepsData[state.step],
    },
  };
  // Entrada á vista com valor total do imóvel, tratar como pagamento á vista
  if (
    nextState.stepsData[5]?.value.intake === nextState.price
    && nextState.stepsData[5]?.value.willSplitIntake === 0
  ) {
    return jumpAndPayInCash(nextState);
  }
  /* Se os valores da simulação já atingiram o valor total do empreendimento,
    pula pra tela de resumo */
  if (nextStep > 5 && nextState.balanceDebt === 0) {
    nextState.step = 10;
    nextState.currentValue = null;
    nextState.stepsData[7] = nextState.stepsData[7] || {
      label: '',
      oldValue: 0,
      value: 0,
    };
  }

  return nextState;
}

function getBreadcrumbReducer(state: any, action: any) {
  const breadcrumbs = [
    ...(new Set([...state.breadcrumb, action.payload]) as any),
  ];
  return {
    ...state,
    breadcrumb: breadcrumbs,
  };
}

function setClickedBreadcrumb(state: any, action: any) {
  return {
    ...state,
    currentBreadcrumb: action.payload,
  };
}

function prepareNextStepReducer(state: any) {
  return {
    ...state,
    nextStepReady: true,
  };
}

function preparePreviousStepReducer(state: any) {
  return {
    ...state,
    previousStepReady: true,
  };
}

function fillRestAfterSelectingFloor(state: any, action: any) {
  return {
    ...state,
    propertySelected: action.payload,
  };
}

function prepareJumpToStep(state: any, action: any) {
  const newStep = action.payload;
  return {
    ...state,
    step: newStep,
    finalStep: newStep === 10,
    jumpToStepReady: true,
    previousStepReady: false,
    nextStepReady: false,
    currentValue: state.stepsData[newStep] || null,
  };
}

function updateDiscount(state: any) {
  const {
    intake,
    propertySelected: { price, valorAporte, valorEntrada },
    stepsData: {
      5: {
        value: { installments },
      },
    },
  } = state;
  const willSplitIntake = !!installments?.length;

  const newState = { ...state };

  if (intake === price) {
    Object.assign(newState, discount1(newState));
    newState.intake = newState.price - newState.discount;
    return newState;
  }
  Object.assign(newState, removeDiscount(newState));

  if (intake >= valorEntrada && intake < valorAporte && !willSplitIntake) {
    Object.assign(newState, discount2(newState));
  } else if (intake >= valorAporte && intake < price) {
    Object.assign(newState, discount3(newState));
  } else {
    Object.assign(newState, removeDiscount(newState));
  }

  return newState;
}

function finishSimulation(state: any) {
  return {
    ...state,
    finalStep: false,
    transitioning: true,
  };
}

function sendProposal(state: any) {
  if (state?.simulationId) {
    return {
      ...state,
      breadcrumb: ['Início', 'Negociações', `Proposta ${state.simulationId}`],
      currentBreadcrumb: `Proposta ${state.simulationId}`,
      proposalSend: true,
    };
  }

  return {
    ...state,
    initialLoading: false,
    error: true,
  };
}

function finishSimulationSuccess(state: any) {
  return {
    ...state,
    transitioning: false,
    finalStep: true,
  };
}

function jumpToStep(state: any) {
  return {
    ...state,
    jumpToStepReady: false,
  };
}

function editIntake(state: any, action: any) {
  return {
    ...state,
    stepsData: {
      ...state.stepsData,
      5: {
        ...state.stepsData[5],
        oldValue: {
          ...state.stepsData[5].oldValue,
          intake: action.payload?.editOldValue
            ? action.payload.newOldValue
            : state.stepsData[5].oldValue.intake,
        },
        value: {
          ...state.stepsData[5].value,
          intake: action.payload?.editOldValue
            ? state.stepsData[5].value.intake
            : action.payload,
        },
      },
    },
  };
}

function editMonthlyInstallment(state: any, action: any) {
  return {
    ...state,
    stepsData: {
      ...state.stepsData,
      6: {
        ...state.stepsData[6],
        oldValue: {
          ...state.stepsData[6].oldValue,
          monthlyInstallment: action.payload?.editOldValue
            ? action.payload.newOldValue
            : state.stepsData[6].oldValue.monthlyInstallment,
        },
        value: {
          ...state.stepsData[6].value,
          monthlyInstallment: action.payload?.editOldValue
            ? state.stepsData[6].value.monthlyInstallment
            : action.payload,
        },
      },
    },
  };
}

function editIsYearlyOrSemiannualPayment(state: any, action: any) {
  let labelValue = 'Nenhum';

  if (action.payload.type === 1) {
    labelValue = 'Anual';
  } else if (action.payload.type === 2) {
    labelValue = 'Semestral';
  }

  return {
    ...state,
    stepsData: {
      ...state.stepsData,
      7: {
        ...state.stepsData[7],
        label: labelValue,
        value: action.payload.type,
      },
    },
  };
}

function editYearlyOrSemiannualValue(state: any, action: any) {
  if (!state.stepsData[8]) {
    return {
      ...state,
      stepsData: {
        ...state.stepsData,
        8: {
          oldValue: {
            yearlyOrSemiannualValue: action.payload.newOldValue,
          },
          value: {
            yearlyOrSemiannualValue: action.payload,
          },
        },
      },
    };
  }
  return {
    ...state,
    stepsData: {
      ...state.stepsData,
      8: {
        ...state.stepsData[8],
        oldValue: {
          ...state.stepsData[8]?.oldValue,
          yearlyOrSemiannualValue: action.payload?.editOldValue
            ? action.payload.newOldValue
            : state.stepsData[8].oldValue.yearlyOrSemiannualValue,
        },
        value: {
          ...state.stepsData[8]?.value,
          yearlyOrSemiannualValue: action.payload?.editOldValue
            ? state.stepsData[8].value.yearlyOrSemiannualValue
            : action.payload,
        },
      },
    },
  };
}

function editSingleInstallment(state: any, action: any) {
  return {
    ...state,
    stepsData: {
      ...state.stepsData,
      9: {
        ...state.stepsData[9],
        oldValue: action.payload?.editOldValue
          ? action.payload.newOldValue
          : state.stepsData[9].oldValue,
        label: action.payload?.editOldValue
          ? state.stepsData[9].label
          : action.payload,
        value: action.payload?.editOldValue
          ? state.stepsData[9].value
          : action.payload,
      },
    },
  };
}

const simulator = createSlice({
  name: 'simulator',
  initialState,
  reducers: {
    EDIT_INTAKE: editIntake,
    EDIT_MONTHLY_INSTALLMENT: editMonthlyInstallment,
    EDIT_YEARLY_OR_SEMIANNUAL: editYearlyOrSemiannualValue,
    EDIT_SINGLE_INSTALLMENT: editSingleInstallment,
    EDIT_IS_YEARLY_OR_SEMIANNUAL_PAYMENT: editIsYearlyOrSemiannualPayment,
    INIT_SIMULATION_SUCCESS: simulationInitiatedSuccess,
    INIT_SIMULATION_ERROR: simulationError,
    INIT_SIMULATION: initSimulation,
    LOAD_SIMULATION: loadSavedSimulation,
    SAVE_SIMULATION: saveSimulation,
    FINISH_SIMULATION: finishSimulation,
    FINISH_SIMULATION_SUCCESS: finishSimulationSuccess,
    SEND_PROPOSAL: sendProposal,
    JUMP_AND_ACCEPT_SUGGESTIONS: jumpAndAcceptSuggestions,
    JUMP_AND_PAY_IN_CASH: jumpAndPayInCash,
    DISCOUNT_1: discount1,
    DISCOUNT_2: discount2,
    DISCOUNT_3: discount3,
    REMOVE_DISCOUNT: removeDiscount,
    UPDATE_DISCOUNT: updateDiscount,
    SET_CURRENT_VALUE: setCurrentValueReducer,
    SET_FINAL_SUMMARY_CALCULUS: setFinalSummaryCalculus,
    SET_FINAL_SUMMARY_VALUES: setFinalSummaryValues,
    SET_INTAKE_INSTALLMENTS: setIntakeInstallments,
    BREADCRUMB: getBreadcrumbReducer,
    SET_CURRENT_BREADCRUMB: setClickedBreadcrumb,
    RESET_SIMULATOR: resetReducer,
    NEXT_STEP: nextStepReducer,
    JUMP_STEP: jumpToStep,
    PREVIOUS_STEP: previousStepReducer,
    PREPARE_JUMP_STEP: prepareJumpToStep,
    PREPARE_NEXT_STEP: prepareNextStepReducer,
    PREPARE_PREV_STEP: preparePreviousStepReducer,
    FILL_REST: fillRestAfterSelectingFloor,
  },
});

const {
  EDIT_INTAKE,
  EDIT_MONTHLY_INSTALLMENT,
  EDIT_YEARLY_OR_SEMIANNUAL,
  EDIT_SINGLE_INSTALLMENT,
  EDIT_IS_YEARLY_OR_SEMIANNUAL_PAYMENT,
  INIT_SIMULATION_SUCCESS,
  INIT_SIMULATION_ERROR,
  INIT_SIMULATION,
  LOAD_SIMULATION,
  SAVE_SIMULATION,
  FINISH_SIMULATION,
  FINISH_SIMULATION_SUCCESS,
  SEND_PROPOSAL,
  JUMP_AND_ACCEPT_SUGGESTIONS,
  JUMP_AND_PAY_IN_CASH,
  DISCOUNT_1,
  DISCOUNT_2,
  DISCOUNT_3,
  REMOVE_DISCOUNT,
  UPDATE_DISCOUNT,
  PREPARE_JUMP_STEP,
  PREPARE_NEXT_STEP,
  PREPARE_PREV_STEP,
  SET_CURRENT_VALUE,
  SET_FINAL_SUMMARY_CALCULUS,
  SET_FINAL_SUMMARY_VALUES,
  SET_INTAKE_INSTALLMENTS,
  BREADCRUMB,
  SET_CURRENT_BREADCRUMB,
  RESET_SIMULATOR,
  PREVIOUS_STEP,
  NEXT_STEP,
  JUMP_STEP,
  FILL_REST,
} = simulator.actions;

export default simulator.reducer;

export {
  EDIT_INTAKE,
  EDIT_MONTHLY_INSTALLMENT,
  EDIT_YEARLY_OR_SEMIANNUAL,
  EDIT_SINGLE_INSTALLMENT,
  EDIT_IS_YEARLY_OR_SEMIANNUAL_PAYMENT,
  INIT_SIMULATION_SUCCESS,
  INIT_SIMULATION_ERROR,
  INIT_SIMULATION,
  LOAD_SIMULATION,
  SAVE_SIMULATION,
  FINISH_SIMULATION,
  FINISH_SIMULATION_SUCCESS,
  SEND_PROPOSAL,
  JUMP_AND_ACCEPT_SUGGESTIONS,
  JUMP_AND_PAY_IN_CASH,
  DISCOUNT_1,
  DISCOUNT_2,
  DISCOUNT_3,
  REMOVE_DISCOUNT,
  UPDATE_DISCOUNT,
  PREPARE_JUMP_STEP,
  PREPARE_NEXT_STEP,
  PREPARE_PREV_STEP,
  SET_CURRENT_VALUE,
  SET_FINAL_SUMMARY_CALCULUS,
  SET_FINAL_SUMMARY_VALUES,
  SET_INTAKE_INSTALLMENTS,
  BREADCRUMB,
  SET_CURRENT_BREADCRUMB,
  RESET_SIMULATOR,
  PREVIOUS_STEP,
  NEXT_STEP,
  JUMP_STEP,
  FILL_REST,
};
