// eslint-disable-next-line
import React from 'react';
import ICheckbox from 'types/Checkbox';
import { DefaultCheckbox } from './styles';

function Checkbox({ onChange, id, checked }: ICheckbox) {
  return <DefaultCheckbox id={id} checked={checked} onChange={onChange} />;
}

export default Checkbox;
