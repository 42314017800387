// eslint-disable-next-line
import React, { useLayoutEffect, useRef, useState } from 'react';
// @ts-ignore-next-line
import { AntDesign } from 'react-web-vector-icons';
import SwiperCore, {
  Navigation, Pagination, Scrollbar, A11y,
} from 'swiper';
import { SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

import {
  CarouselContainer,
  CustomCarousel,
  AbsoluteRoundButtonLeft,
  AbsoluteRoundButtonRight,
} from './styles';

interface Props {
  children: any;
  cardWidth?: number;
}

function HomeCarousel({ children, cardWidth = 300 }: Props) {
  const [
    { renderLeftButton, renderRightButton },
    setRenderButton,
  ] = useState<any>({ renderLeftButton: false, renderRightButton: true });
  const [breakPoints, setBreakPoints] = useState<any>(null);
  const carouselRef = useRef<HTMLElement>(null);

  useLayoutEffect(() => {
    const { current: ref } = carouselRef;
    if (ref && children?.length > 0) {
      const slidesOffsetBefore = ref?.scrollWidth * 0.041;
      const slidesOffsetAfter = ref?.scrollWidth * 0.041;

      setBreakPoints({
        300: {
          slidesPerGroup: 1.01,
          slidesPerView: 1.01,
        },
        450: {
          slidesPerGroup: 2,
          slidesPerView: 2,
          slidesOffsetBefore,
          slidesOffsetAfter,
        },
        690: {
          slidesPerGroup: 2.2,
          slidesPerView: 2.2,
          slidesOffsetBefore,
          slidesOffsetAfter,
        },
        800: {
          slidesPerGroup: 2.4,
          slidesPerView: 2.4,
          slidesOffsetBefore,
          slidesOffsetAfter,
        },
        942: {
          slidesPerGroup: 2.8,
          slidesPerView: 2.8,
          slidesOffsetBefore,
          slidesOffsetAfter,
        },
        1040: {
          slidesPerGroup: 2.85,
          slidesPerView: 2.85,
          slidesOffsetBefore,
          slidesOffsetAfter,
        },
        1200: {
          slidesPerGroup: 3.15,
          slidesPerView: 3.15,
          slidesOffsetBefore,
          slidesOffsetAfter,
        },
        1300: {
          slidesPerGroup: 3.35,
          slidesPerView: 3.35,
          slidesOffsetBefore,
          slidesOffsetAfter,
        },
        1400: {
          slidesPerGroup: 3.5,
          slidesPerView: 3.5,
          slidesOffsetBefore,
          slidesOffsetAfter,
        },
        1600: {
          slidesPerGroup: 3.5,
          slidesPerView: 3.5,
          slidesOffsetBefore,
          slidesOffsetAfter,
        },
        1900: {
          slidesPerGroup: 4.2,
          slidesPerView: 4.2,
          slidesOffsetBefore,
          slidesOffsetAfter,
        },
        2200: {
          slidesPerGroup: 4.8,
          slidesPerView: 4.8,
          slidesOffsetBefore,
          slidesOffsetAfter,
        },
        2500: {
          slidesPerGroup: 5.5,
          slidesPerView: 5.5,
          slidesOffsetBefore,
          slidesOffsetAfter,
        },
        2800: {
          slidesPerGroup: 6,
          slidesPerView: 6,
          slidesOffsetBefore,
          slidesOffsetAfter,
        },
        3100: {
          slidesPerGroup: 6.7,
          slidesPerView: 6.7,
          slidesOffsetBefore,
          slidesOffsetAfter,
        },
      });
    }
  }, [carouselRef, children]);

  SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

  return (
    <CarouselContainer ref={carouselRef}>
      {breakPoints && (
        <CustomCarousel
          speed={800}
          freeMode={children?.length > 4}
          onReachEnd={() => {
            setRenderButton({
              renderLeftButton: true,
              renderRightButton: false,
            });
          }}
          spaceBetween={10}
          onReachBeginning={() => {
            setRenderButton({
              renderLeftButton: false,
              renderRightButton: true,
            });
          }}
          onSlideChange={(slide) => {
            setRenderButton({
              renderLeftButton: !slide.isBeginning,
              renderRightButton: !slide.isEnd,
            });
          }}
          slidesOffsetBefore={0}
          slidesOffsetAfter={0}
          breakpoints={breakPoints}
          navigation={{
            prevEl: '.prev',
            nextEl: '.next',
          }}
        >
          {children?.length > 4 && (
            <AbsoluteRoundButtonLeft
              renderLeftButton={renderLeftButton}
              cardWidth={cardWidth}
              className="prev"
            >
              <AntDesign name="arrowleft" size={30} />
            </AbsoluteRoundButtonLeft>
          )}
          {React.Children.map(children, (child) => (
            <SwiperSlide>{child}</SwiperSlide>
          ))}
          {children?.length > 4 && (
            <AbsoluteRoundButtonRight
              renderRightButton={renderRightButton}
              cardWidth={cardWidth}
              className="next"
            >
              <AntDesign name="arrowright" size={30} />
            </AbsoluteRoundButtonRight>
          )}
        </CustomCarousel>
      )}
    </CarouselContainer>
  );
}

HomeCarousel.defaultProps = {
  cardWidth: 370,
};

export default HomeCarousel;
