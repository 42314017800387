import styled from 'styled-components';

export const DefaultFloatingAlertMessage = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  padding: 20px;
  width: fit-content;
  height: 40px;
  border-radius: 24px;
  box-shadow: 0 1px 11px 0 rgba(139, 106, 236, 0.2),
    0 0 10px 0 rgba(203, 186, 252, 0.15), 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  div {
    margin-right: 10px;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const SecureOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  z-index: 99;
`;
