import colors from '@colors';

function useStyledErrorLabel(errortype: string | null) {
  switch (errortype) {
    case 'warn':
      return `
              color: ${colors.yellowWarn};
          `;
    case 'error':
      return `
              color: ${colors.redError};
          `;

    default:
      return `color: ${colors.textMediumEmphasis};`;
  }
}

export default useStyledErrorLabel;
