// eslint-disable-next-line
import React, { ReactNode, useEffect } from 'react';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';

interface Props {
  children: ReactNode;
}

const ScrollOnRouteChange: React.FunctionComponent<
  Props & RouteComponentProps
> = ({ children }: Props) => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

export default withRouter(ScrollOnRouteChange);
