import { hideHeaderFooter, showHeaderFooter } from '@actions/hideHeaderFooter';
import { useDispatch, useSelector } from 'react-redux';

export default function useHideHeaderFooter() {
  const status = useSelector(({ hideHeaderFooter }: any) => hideHeaderFooter);
  const dispatch = useDispatch();

  function setHideHeaderFooter(newStatus: boolean) {
    if (!newStatus) {
      dispatch(hideHeaderFooter());
    } else {
      dispatch(showHeaderFooter());
    }
  }

  return {
    status,
    setHideHeaderFooter,
  };
}
