import colors from '@colors';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 50em;
  @media (max-width: 480px) {
    margin-bottom: 50px;
  }
`;

export const Form = styled.form`
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 25px;
  flex-grow: 0.4;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const NotHaveAccount = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 370px;
  border-radius: 24px;
  justify-content: space-between;
  flex-grow: 1;
`;

export const Logo = styled.div`
  img {
    width: 148px;
    height: auto;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 10em;
  @media (max-width: 480px) {
    height: unset;
  }
`;

export const SignupSuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 25em;
  button {
    width: 300px;
    border-radius: 24px;
  }
  p {
    width: 300px;
  }
`;

export const SignupSuccessIcon = styled.div`
  background-color: ${colors.greenSuccess};
  width: 55px;
  height: 55px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    color: white !important;
  }
`;

export const RememberMeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  a {
    white-space: pre;
  }
`;

export const SocialMediaLogin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex-grow: 0.4;
  height: 50%;
  justify-content: space-around;
  button {
    text-transform: unset;
    border-radius: 24px;
  }
  label {
    width: calc(100% + 150px);
    @media (max-width: 480px) {
      width: calc(100% + 30px);
    }
    text-align: center;
    border-bottom: 1px solid #efeff4;
    line-height: 0.1em;
    margin: 10px 0 20px;
    padding: 0 10px;
    span {
      background: white;
      padding: 0 20px;
    }
  }
`;

export const DefaultSignup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  flex-grow: 0.3;
  label {
    width: calc(100% + 150px);
    @media (max-width: 480px) {
      width: calc(100% + 30px);
    }
    text-align: center;
    border-bottom: 1px solid #efeff4;
    line-height: 0.1em;
    margin: 10px 0 20px;
    padding: 0 10px;
    span {
      background: white;
      padding: 0 20px;
    }
  }
`;
