import { saveUserFav } from '@services/firebase';
import { useEffect, useState } from 'react';
import Signin from '@pages/Signin';
import useModal from './useModal';
import useGetUser from './useGetUser';

const useFavorites = () => {
  const { user, updateUserStore } = useGetUser();
  const [favs, setFavs] = useState<string[]>([]);
  const [addedFav, setAddedFav] = useState(false);
  const { show } = useModal();

  useEffect(() => {
    if (user) {
      setFavs(user.favorites);
    } else {
      setFavs([]);
    }
  }, [user]);

  async function addOrRemoveFav(id: string) {
    if (!user) {
      show(Signin, {
        hideBackButton: true,
      });
    } else {
      if (!favs.includes(id)) {
        setFavs((prevFavs: string[]) => [...prevFavs, id]);
      } else {
        setFavs((prevFavs: string[]) => prevFavs.filter((prevFavsId) => prevFavsId !== id));
      }
      const { success, data } = await saveUserFav(id);
      if (success) {
        const { added } = data;
        setAddedFav(added);
        await updateUserStore();
      }
    }
  }

  return {
    favs,
    addedFav,
    addOrRemoveFav,
  };
};

export default useFavorites;
