// eslint-disable-next-line
import React, { useState, lazy, Suspense } from 'react';
// @ts-ignore
import { AntDesign } from 'react-web-vector-icons';
import { FaBars } from 'react-icons/fa';

import { Menu, Button, Dropdown } from 'antd';

import { ReactComponent as Person } from '@assets/Icons/person-circle.svg';
import { ReactComponent as Propose } from '@assets/Icons/document.svg';
import { ReactComponent as Heart } from '@assets/Icons/heart-filled.svg';
import { ReactComponent as SignOut } from '@assets/Icons/sign-out.svg';
import { ReactComponent as Ouro } from '@assets/Icons/ouro.svg';

import Logo from '@assets/Logo/LogoImovClick.svg';
import { useModal, useGetUser, useHideHeaderFooter } from '@hooks';
import Signin from '@pages/Signin';
import { useHistory } from 'react-router-dom';
import {
  LogoBox,
  MenuBox,
  Wrapper,
  Overlay,
  LoginBox,
  Container,
  CustomMenu,
  UserLoggedContainer,
  MenuOverlayContainer,
  HamburgerMenuContainer,
  HideHeaderContainer,
} from './styles';

const DownOutlined = lazy(() => import('@ant-design/icons')
  .then((module) => ({ default: module.DownOutlined })));

const Header = () => {
  const { user, logout, updateUserStore } = useGetUser();
  const { show } = useModal();
  const { status, setHideHeaderFooter } = useHideHeaderFooter();
  const [menuOpen, setMenuOpen] = useState(false);
  const history = useHistory();

  async function setPage(page: string) {
    await updateUserStore();
    history.push(page);
    setMenuOpen(false);
  }

  const menuLogin = (
    <MenuOverlayContainer>
      <Menu>
        <Menu.Item
          onClick={() => setPage('/minhaconta')}
          icon={<Person />}
          key="perfil"
        >
          Meu perfil
        </Menu.Item>
        <Menu.Item
          key="negociacoes"
          onClick={() => setPage('/negociacoes')}
          icon={<Propose />}
        >
          Negociações
        </Menu.Item>
        <Menu.Item
          onClick={() => setPage('/favoritos')}
          key="favoritos"
          icon={<Heart />}
        >
          Favoritos
        </Menu.Item>
        <Menu.Item
          onClick={() => setPage('/meus-pre-lancamentos')}
          key="meus-pre-lancamentos"
          icon={<Ouro />}
        >

          Meus pré-lançamentos
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key="logout"
          onClick={async () => {
            await logout();
          }}
          icon={<SignOut />}
        >
          Logout
        </Menu.Item>
      </Menu>
    </MenuOverlayContainer>
  );

  function LoginButton() {
    return (
      <LoginBox>
        {!user ? (
          <Button
            onClick={() => {
              show(Signin, {
                hideBackButton: true,
              });
            }}
          >
            LOGIN
          </Button>
        ) : (
          <Dropdown trigger={['click']} overlay={menuLogin}>
            <Button>
              Minha conta
              <Suspense fallback={<></>}>
                <DownOutlined />
              </Suspense>
            </Button>
          </Dropdown>
        )}
      </LoginBox>
    );
  }

  function HamburgerMenu() {
    return (
      <>
        <HamburgerMenuContainer>
          <LoginButton />
          <div onClick={() => setMenuOpen(!menuOpen)}>
            {menuOpen ? (
              <AntDesign name="close" size={30} />
            ) : (
              <FaBars size={30} />
            )}
          </div>
          {menuOpen && (
            <CustomMenu mode="vertical">
              <Menu.Item onClick={() => setPage('/sobre')} key="sobrenos">
                Sobre nós
              </Menu.Item>

              <Menu.Item
                key="lancamentos"
                onClick={() => setPage('/lancamentos')}
              >
                Lançamentos
              </Menu.Item>

              <Menu.Item
                key="prelancamentos"
                onClick={() => setPage('/pre-lancamentos')}
              >
                Pré-lançamentos
              </Menu.Item>
              <Menu.Item onClick={() => setPage('/faq')} key="perguntas">
                Perguntas frequentes
              </Menu.Item>

              <Menu.Divider />
              {user ? (
                <UserLoggedContainer>
                  <Menu.Item
                    key="perfil"
                    onClick={() => setPage('/minhaconta')}
                    icon={<Person />}
                  >
                    Meu perfil
                  </Menu.Item>
                  <Menu.Item
                    key="negociacoes"
                    onClick={() => { setPage('/negociacoes'); }}
                    icon={<Propose />}
                  >
                    Negociações
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => setPage('/favoritos')}
                    key="favoritos"
                    icon={<Heart />}
                  >
                    Favoritos
                  </Menu.Item>
                  <Menu.Item
                    key="logout"
                    onClick={() => {
                      setMenuOpen(false);
                      logout();
                    }}
                    icon={<SignOut />}
                  >
                    Logout
                  </Menu.Item>
                </UserLoggedContainer>
              ) : (
                <UserLoggedContainer>
                  <Menu.Item
                    onClick={() => {
                      show(Signin, {
                        hideBackButton: true,
                      });
                    }}
                    key="login"
                  >
                    Login
                  </Menu.Item>
                </UserLoggedContainer>
              )}
            </CustomMenu>
          )}
        </HamburgerMenuContainer>
      </>
    );
  }

  if (status) {
    return (
      <HideHeaderContainer>
        <LogoBox
          title="Home"
          onClick={() => {
            setHideHeaderFooter(false);
            history.push('/');
          }}
        >
          <img src={Logo} alt="Logo ImovClick" width="148px" height="auto" />
        </LogoBox>
      </HideHeaderContainer>
    );
  }

  return (
    <>
      <Container>
        <Wrapper>
          <LogoBox
            title="Home"
            onClick={() => {
              setHideHeaderFooter(false);
              history.push('/');
            }}
          >
            <img src={Logo} alt="Logo ImovClick" width="148px" height="auto" />
          </LogoBox>
          <MenuBox>
            <Menu mode="horizontal">
              <Menu.Item onClick={() => history.push('/sobre')} key="sobrenos">
                Sobre nós
              </Menu.Item>
              <Menu.Item
                key="prelancamentos"
                onClick={() => history.push('/pre-lancamentos')}
              >
                Pré-lançamentos
              </Menu.Item>
              <Menu.Item
                key="lancamentos"
                onClick={() => history.push('/lancamentos')}
              >
                Lançamentos
              </Menu.Item>
              <Menu.Item onClick={() => history.push('/faq')} key="perguntas">
                Perguntas frequentes
              </Menu.Item>
            </Menu>
          </MenuBox>
          <HamburgerMenu />
          <LoginButton />
        </Wrapper>
      </Container>
      {menuOpen && <Overlay onClick={() => setMenuOpen(false)} />}
    </>
  );
};

export default Header;
