// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
import { AutoComplete, Select } from 'antd';
import { useDebounce } from 'use-debounce';

import { findRealEstatesToAutocomplete } from '@services/squidex';
import colors from '@colors';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '@components/LoadingSpinner';
import {
  SearchBox, Search, SearchButton, Available,
} from './styles';

interface Props {
  hasAutocomplete?: boolean
}

function MainSearch({ hasAutocomplete = false }: Props) {
  const { Option } = Select;
  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState([]);
  const [cancelSearchFromURL, setCancelSearchFromURL] = useState(false);
  const [debouncedSearch] = useDebounce(searchValue, 800);
  const [fetchingAutoComplete, setFetchingAutoComplete] = useState(false);
  const [showAvailable, setShowAvailable] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!searchValue) {
      const params = new URLSearchParams(window.location.search);
      const searchedValueFromURL = params.get('termo');
      if (searchedValueFromURL && !cancelSearchFromURL) {
        setSearchValue(searchedValueFromURL);
        setShowAvailable(false);
      }
    }
    if (debouncedSearch && hasAutocomplete) {
      fetchAutoComplete();
    } // eslint-disable-next-line
  }, [debouncedSearch]);

  async function fetchAutoComplete() {
    setFetchingAutoComplete(true);
    const { success, data } = await findRealEstatesToAutocomplete(searchValue);

    if (success) {
      setOptions(
        data?.items.map(({ data, id }: any) => ({
          id,
          description: `${data.nome.charAt(0).toUpperCase() + data.nome.slice(1).toLowerCase()} - ${data.rua}, ${data.numero} - ${data.bairro} - ${data.cidade} - ${data.estado}`,
          slug: data.slug,
        })),
      );
      setFetchingAutoComplete(false);
    }
  }

  useEffect(() => {
    if (searchValue.length === 0) {
      setCancelSearchFromURL(true);
    }
  }, [searchValue]);

  return (
    <>
      <SearchBox>
        <Search>
          <AutoComplete
            allowClear={false}
            value={searchValue}
            showArrow={false}
            searchValue={searchValue}
            onSearch={(e) => setSearchValue(e)}
            notFoundContent={fetchingAutoComplete ? <LoadingSpinner /> : null}
            placeholder="Busque na cidade de São Paulo..."
            onSelect={(value: any) => {
              const { description, id, slug } = JSON.parse(value);
              setSearchValue(description);
              history.push({
                pathname: `/empreendimento/${slug}`,
                state: { description, id, slug },
              });
            }}
          >
            {options?.map(({ description, id, slug }) => (
              <Option
                key={id}
                value={JSON.stringify({
                  description,
                  id,
                  slug,
                })}
              >
                {description}
              </Option>
            ))}
          </AutoComplete>
          <SearchButton
            onClick={() => {
              if (searchValue.length !== 0) {
                history.push({
                  pathname: '/busca',
                  search: `?termo=${encodeURI(searchValue)}`,
                  state: options[0],
                });
                history.go(0);
              } else {
                history.push({
                  pathname: '/busca',
                  search: `?termo=${encodeURI('São Paulo, Brasil')}`,
                  state: {
                    description: 'São Paulo, Brasil',
                  },
                });
              }
            }}
            type="button"
            color={colors.primary}
          >
            BUSCAR IMÓVEL
          </SearchButton>
        </Search>
        {showAvailable && (
          <Available>
            <p>Disponível nas cidades: </p>
            <span>SÃO PAULO</span>
          </Available>
        )}
      </SearchBox>
    </>
  );
}

export default MainSearch;
