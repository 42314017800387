import styled from 'styled-components';
import colors from '@colors';

export const LoginWithBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  height: 50%;
  justify-content: space-around;

  button.ant-btn {
    border-color: ${colors.border};
    :last-child {
      margin-top: 20px;
    }
    height: 40px;
    width: 100%;
    border-radius: 24px;
  }

  .ant-btn > span:hover {
    color: ${colors.primary};
  }

  svg {
    position: absolute;
    left: 10px;
  }


  .ant-btn:hover,
  .ant-btn:focus {
    background-color: ${colors.foreground};
    border-color: ${colors.primary};
  }
`;
