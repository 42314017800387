import { createContext } from 'react';
import ICardContext from 'types/CardContext';

const cardsize = (() => {
  if (window.outerWidth <= 480) {
    return {
      width: 324,
      height: 425,
    };
  } if (window.outerWidth <= 1024) {
    return {
      width: 324,
      height: 425,
    };
  } if (window.outerWidth <= 1480) {
    return {
      width: 370,
      height: 425,
    };
  }
  return {
    width: 420,
    height: 464,
  };
})();

const CardContext = createContext<ICardContext>({ cardsize });

export default CardContext;
