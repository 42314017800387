const initialState = {
  show: false,
  modalProps: {},
  component: null,
};

function modal(state = initialState, action: any) {
  switch (action.type) {
    case 'SHOW_MODAL':
      return {
        ...state,
        show: true,
        component: action.component,
        modalProps: action.modalProps,
      };

    case 'HIDE_MODAL':
      return {
        ...state,
        show: false,
        component: null,
        modalProps: {},
      };

    default:
      return state;
  }
}

export default modal;
