import { combineReducers } from 'redux';

const initialState = {
  loading: false,
  success: false,
  error: null,
  data: [],
};

const getUserInitialState = {
  user: null,
  userSignedIn: null,
};

const resetPasswordInitialState = {
  isVerifyingActionCode: false,
  isResettingPassword: false,
  actionCodeExpired: false,
  success: false,
  error: null,
};

function signUp(state = initialState, action: any) {
  switch (action.type) {
    case 'RESET_SIGN_UP_STATE':
      return initialState;

    case 'FETCH_SIGN_UP':
      return {
        ...state,
        loading: true,
        error: null,
      };

    case 'FETCH_SIGN_UP_SUCCESS':
      return {
        ...state,
        data: action.payload,
        loading: false,
        success: true,
        error: null,
      };

    case 'FETCH_SIGN_UP_ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}

function signIn(state = initialState, action: any) {
  switch (action.type) {
    case 'RESET_SIGN_IN_STATE':
      return initialState;

    case 'FETCH_SIGN_IN':
      return {
        ...state,
        loading: true,
        error: null,
      };

    case 'FETCH_SIGN_IN_SUCCESS':
      return {
        ...state,
        data: action.payload,
        loading: false,
        success: true,
        error: null,
      };

    case 'FETCH_SIGN_IN_ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}

function forgotPassword(state = initialState, action: any) {
  switch (action.type) {
    case 'RESET_FORGOT_PASS_STATE':
      return initialState;

    case 'FETCH_FORGOT_PASS':
      return {
        ...state,
        loading: true,
        error: null,
      };

    case 'FETCH_FORGOT_PASS_SUCCESS':
      return {
        ...state,
        data: action.payload,
        loading: false,
        success: true,
        error: null,
      };

    case 'FETCH_FORGOT_PASS_ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}

function getUser(state = getUserInitialState, action: any) {
  switch (action.type) {
    case 'USER_GET_USER':
      return {
        ...state,
        userSignedIn: true,
        user: action.payload,
        logout: false,
      };

    case 'USER_NOT_SIGNED_IN':
      return {
        ...state,
        userSignedIn: false,
        user: null,
        logout: false,
      };

    case 'USER_LOGOUT':
      localStorage.clear();
      return {
        ...state,
        userSignedIn: false,
        user: null,
        logout: true,
      };

    default:
      return state;
  }
}

function resetPassword(state = resetPasswordInitialState, action: any) {
  switch (action.type) {
    case 'FETCH_VERIFY_ACTION_CODE':
      return {
        ...state,
        isVerifyingActionCode: true,
        error: null,
      };

    case 'FETCH_VERIFY_ACTION_CODE_SUCCESS':
      return {
        ...state,
        isVerifyingActionCode: false,
        actionCodeExpired: false,
      };

    case 'FETCH_VERIFY_ACTION_CODE_ERROR':
      return {
        ...state,
        isVerifyingActionCode: false,
        actionCodeExpired: true,
      };

    case 'FETCH_RESET_PASSWORD':
      return {
        ...state,
        isResettingPassword: true,
        error: null,
      };

    case 'FETCH_RESET_PASSWORD_SUCCESS':
      return {
        ...state,
        isResettingPassword: false,
        success: true,
      };

    case 'FETCH_RESET_PASSWORD_ERROR':
      return {
        ...state,
        isResettingPassword: false,
        error: action.payload,
      };

    default:
      return state;
  }
}

export default combineReducers({
  signUp,
  signIn,
  getUser,
  forgotPassword,
  resetPassword,
});
