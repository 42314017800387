import {
  B4U_FIREBASE_API_KEY,
  B4U_FIREBASE_AUTH_DOMAIN,
  B4U_FIREBASE_DATABASE_URL,
  B4U_FIREBASE_PROJECT_ID,
  GCLOUD_STORAGE_BUCKET,
  B4U_FIREBASE_MESSAGING_SENDER_ID,
  B4U_FIREBASE_APP_ID,
} from '@constants/enviroment';

let firebaseApp: any;
let firebase: any;

async function getFirebaseApp() {
  if (!firebaseApp) {
    firebase = await import('firebase/app');
    await import('firebase/auth');

    firebaseApp = firebase.initializeApp({
      apiKey: B4U_FIREBASE_API_KEY,
      authDomain: B4U_FIREBASE_AUTH_DOMAIN,
      databaseURL: B4U_FIREBASE_DATABASE_URL,
      projectId: B4U_FIREBASE_PROJECT_ID,
      storageBucket: GCLOUD_STORAGE_BUCKET,
      messagingSenderId: B4U_FIREBASE_MESSAGING_SENDER_ID,
      appId: B4U_FIREBASE_APP_ID,
    });
  }

  return { firebase, firebaseApp };
}

export default getFirebaseApp;
