import { Row } from 'antd';
import colors from '@colors';
import styled from 'styled-components';

export const SearchHeader = styled(Row).attrs({ gutter: { xxl: 24 } })`
  display: flex;
  flex-direction: column;
  margin: 100px auto;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 480px) {
    padding: 0;
    margin: 0px 0 50px auto;
  }

  @media (max-width: 1024px) {
    padding: 0;
  }
`;
export const Section = styled.div`
  width: 100%;
  justify-content: center;
`;

export const SectionHeader = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    text-transform: uppercase;
    font-size: 15px;
    font-weight: bolder;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    padding: 6px;
  }

  a:hover {
    background-color: ${colors.foreground} !important;
    border-radius: 5px;
    padding: 6px;
  }
`;

export const HowItWorks = styled.div`
  background-color: rgba(238, 241, 255, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto -4.55%;
  padding: 100px 20px;
  justify-content: center;
  @media (max-width: 1280px) {
    div {
      flex-wrap: wrap;
    }
  }
  div div:not(:last-child) {
    margin-right: 30px;
    @media (max-width: 480px) {
      margin-right: 0;
    }
  }
  div {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1170px;
    justify-content: center;
    div {
      margin-top: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      min-height: 331px;
      padding: 30px;
      background-color: white;
      border-radius: 20px;
      @media (min-width: 480px) {
        max-width: 270px;
      }
      p {
        width: 100%;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        
        text-align: left;
      }
    }
  }
  h1 {
    max-width: 435px;
    text-align: center;
  }
`;
