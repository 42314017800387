// @ts-nocheck
// eslint-disable-next-line
import React, { useContext, useEffect, useState, lazy, Suspense } from 'react';
import Card from '@components/Card';
import HomeCarousel from '@components/HomeCarousel';
import MainSearch from '@components/MainSearch';
import { getAllFavs } from '@services/firebase';
import {
  CardSkeleton,
  CardHeadingSkeleton,
} from '@components/Card/CardSkeleton';
import { useHistory } from 'react-router-dom';
import CardContext from '@context/CardContext';
import ICardContext from 'types/CardContext';
import { Col } from 'antd';
import { useGetUser } from '@hooks';
import { useSwrFetch } from '../../hooks/useSwrFetch';
import {
  Section, SectionHeader, SearchHeader,
} from './styles';

const HowItWorksComponent = lazy(() => import('./HowItWorksComponent'));

function Home() {
  const [favs, setFavs] = useState([]);
  const { user } = useGetUser();
  const [realEstatesReleases, setRealEstatesReleases] = useState<any>([]);
  const [realEstatesPreReleases, setRealEstatesPreReleases] = useState<any>([]);
  const [loadingRealEstates, setLoadingRealEstates] = useState(false);
  const { cardsize } = useContext<ICardContext>(CardContext);
  const history = useHistory();

  const { data: realEstate } = useSwrFetch<any>({
    url: '/squidex/realestate',
  });

  async function allFavs() {
    const favorites = await getAllFavs();
    if (favorites) {
      setFavs(favorites.data);
    }
  }

  useEffect(() => {
    async function fetchRealEstates() {
      setLoadingRealEstates(!realEstate?.data);
      if (realEstate?.success) {
        setRealEstatesPreReleases(
          realEstate?.data?.filter(({ data: { preLancamento } }: any) => preLancamento).slice(0, 9),
        );
        setRealEstatesReleases(
          realEstate?.data?.filter(({ data: { preLancamento } }: any) => !preLancamento)
            .sort((a: any, b: any) => (b.data.destaqueRank || 0) - (a.data.destaqueRank || 0))
            .slice(0, 9),
        );
        setLoadingRealEstates(false);
      }
    }

    if (user) {
      allFavs();
    }
    fetchRealEstates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realEstate]);

  return (
    <>
      <SearchHeader>
        <Col>
          <h1>Seu empreendimento dos sonhos está aqui</h1>
          <h4>Faça sua proposta online. É prático, rápido e seguro.</h4>
          <MainSearch hasAutocomplete />
        </Col>
      </SearchHeader>
      <div>
        <Section>
          <SectionHeader>
            {loadingRealEstates ? (
              <CardHeadingSkeleton />
            ) : (
              realEstatesReleases?.length > 0 && <h3>Lançamentos</h3>
            )}
            {realEstatesReleases?.length === 9 && (
              <a
                href="_blank"
                onClick={(e) => {
                  e.preventDefault();
                  history.push({
                    pathname: '/busca',
                    search: `/busca?termo=${encodeURI(
                      'São Paulo, Brasil',
                    )}&lancamento=true`,
                    state: {
                      description: 'São Paulo, Brasil',
                    },
                  });
                }}
              >
                Ver todos
              </a>
            )}
          </SectionHeader>
          {loadingRealEstates ? (
            <HomeCarousel cardWidth={cardsize.width}>
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
            </HomeCarousel>
          ) : (
            <HomeCarousel cardWidth={cardsize.width}>
              {realEstatesReleases?.map(
                ({ id, data: { imagemCard, ...data } }: any, index: number) => (
                  <Card
                    key={id}
                    id={id}
                    allFavs={favs}
                    imagemPreview={imagemCard || ''}
                    imageWidth={cardsize.width}
                    {...data}
                    imagePreload={(index < 4)}
                  />
                ),
              )}
            </HomeCarousel>
          )}
        </Section>
        <Section>
          <SectionHeader>
            {loadingRealEstates ? (
              <CardHeadingSkeleton />
            ) : (
              realEstatesPreReleases?.length > 0 && <h3>Pré-Lançamentos</h3>
            )}
            {realEstatesPreReleases?.length === 9 && (
              <a
                href="_blank"
                onClick={(e) => {
                  e.preventDefault();
                  history.push({
                    pathname: '/busca',
                    search: `/busca?termo=${encodeURI(
                      'São Paulo, Brasil',
                    )}&prelancamento=true`,
                    state: {
                      description: 'São Paulo, Brasil',
                    },
                  });
                }}
              >
                Ver todos
              </a>
            )}
          </SectionHeader>
          {loadingRealEstates ? (
            <HomeCarousel cardWidth={cardsize.width}>
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
            </HomeCarousel>
          ) : (
            <HomeCarousel cardWidth={cardsize.width}>
              {realEstatesPreReleases?.map(
                ({ id, data: { imagemCard, ...data } }: any, index: number) => (
                  <Card
                    key={id}
                    id={id}
                    allFavs={favs}
                    imagemPreview={imagemCard || ''}
                    user={user}
                    imageWidth={cardsize.width}
                    {...data}
                    imagePreload={(index < 4)}
                  />
                ),
              )}
            </HomeCarousel>
          )}
        </Section>
        <Suspense fallback={<></>}>
          <HowItWorksComponent />
        </Suspense>
      </div>
    </>
  );
}

export default Home;
