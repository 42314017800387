import styled from 'styled-components';
import { Checkbox } from 'antd';
import colors from '@colors';

export const DefaultCheckbox = styled(Checkbox)`
  padding-right: 10px;
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${colors.primary};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${colors.primary};
  }
`;
