import { useEffect, useState } from 'react';
import { FieldErrors } from 'react-hook-form';

function useComponentErrorInput(name: string, errors: FieldErrors) {
  const [errortype, setErrorType] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (errors[name]) {
      const { message, type } = errors[name];
      if (type === 'error' || type === 'warn' || type === 'info') {
        setErrorType(type);
      } else {
        setErrorType('error');
      }
      setErrorMessage(message);
    } else {
      setErrorType(null);
      setErrorMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(errors)]);

  return {
    errortype,
    errorMessage,
  };
}

export default useComponentErrorInput;
