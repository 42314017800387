export const { NODE_ENV } = process.env;
export const BASE_URL = window.location.hostname === process.env.B4U_FIREBASE_PROD_DOMAIN
  ? process.env.REACT_APP_SERVER_BASE_URL_PROD
  : process.env.REACT_APP_SERVER_BASE_URL_DEV;
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const WHATSAPP_PHONENUMBER = process.env.REACT_APP_WHATSAPP_PHONENUMBER;
export const GCLOUD_STORAGE_BUCKET = process.env.REACT_APP_GCLOUD_STORAGE_BUCKET;
export const B4U_FIREBASE_DATABASE_URL = process.env.REACT_APP_B4U_FIREBASE_DATABASE_URL;
export const B4U_FIREBASE_API_KEY = process.env.REACT_APP_B4U_FIREBASE_API_KEY;
export const B4U_FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_B4U_FIREBASE_AUTH_DOMAIN;
export const B4U_FIREBASE_PROJECT_ID = process.env.REACT_APP_B4U_FIREBASE_PROJECT_ID;
export const B4U_FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_B4U_FIREBASE_MESSAGING_SENDER_ID;
export const B4U_FIREBASE_APP_ID = process.env.REACT_APP_B4U_FIREBASE_APP_ID;
export const B4U_FIREBASE_DEV_DOMAIN = process.env.REACT_APP_B4U_FIREBASE_DEV_DOMAIN;
export const B4U_SQUIDEX_ASSETS_URL = process.env.REACT_APP_B4U_SQUIDEX_ASSETS_URL;
export const B4U_INSTAGRAM_URL = process.env.REACT_APP_B4U_INSTAGRAM_URL;
export const B4U_FACEBOOK_URL = process.env.REACT_APP_B4U_FACEBOOK_URL;
export const B4U_LINKEDIN_URL = process.env.REACT_APP_B4U_LINKEDIN_URL;
