// eslint-disable-next-line
import React from 'react';
// @ts-ignore
import { Foundation } from 'react-web-vector-icons';
import { useComponentErrorInput } from '@hooks';
import { useFormContext, Controller } from 'react-hook-form';
import IInput from 'types/Input';
import colors from '@colors';
import {
  LabeledInputContainer,
  DefaultInput,
  DefaultInputPassword,
  Label,
  ErrorMessage,
} from './styles';

function Input({
  placeholder,
  width,
  height,
  labelText,
  name,
  bottomMessage,
  prefixIcon,
  type,
  defaultValue,
}: IInput) {
  const { control, errors } = useFormContext();
  const { errortype, errorMessage } = useComponentErrorInput(name, errors);

  const message = errorMessage || bottomMessage;

  return (
    <LabeledInputContainer>
      <Label>{labelText}</Label>
      <Controller
        defaultValue={defaultValue}
        as={DefaultInput}
        type={type}
        prefix={prefixIcon}
        suffix={
          errortype === 'warn' ? (
            <Foundation color={colors.yellowWarn} name="alert" size={20} />
          ) : (
            <span />
          )
        }
        errortype={errortype}
        placeholder={placeholder}
        width={width}
        height={height}
        name={name || ''}
        control={control}
      />
      <ErrorMessage errortype={errortype}>{message}</ErrorMessage>
    </LabeledInputContainer>
  );
}

function Password({
  placeholder,
  width,
  height,
  labelText,
  visibilityToggle,
  name,
  bottomMessage,
  prefixIcon,
}: IInput) {
  const { control, errors } = useFormContext();
  const { errortype, errorMessage } = useComponentErrorInput(name, errors);

  const message = errorMessage || bottomMessage;

  return (
    <LabeledInputContainer>
      <Label>{labelText}</Label>
      <Controller
        as={DefaultInputPassword}
        name={name || ''}
        prefix={prefixIcon}
        suffix={
          errortype === 'warn' ? (
            <Foundation color={colors.yellowWarn} name="alert" size={20} />
          ) : (
            <span />
          )
        }
        visibilityToggle={visibilityToggle}
        errortype={errortype}
        placeholder={placeholder}
        width={width}
        height={height}
        control={control}
      />
      <ErrorMessage errortype={errortype}>{message}</ErrorMessage>
    </LabeledInputContainer>
  );
}

Input.defaultProps = {
  placeholder: '',
  width: '370px',
  borderRadius: '24px',
  height: '40px',
  labelText: '',
  errorMessage: '',
  errortype: null,
  visibilityToggle: true,
  name: '',
};

Input.Password = Password;

Password.defaultProps = Input.defaultProps;

export default Input;
