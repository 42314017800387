import styled from 'styled-components';
import { Modal } from 'antd';

export const DefaultModal = styled(Modal) <any>`
  .ant-modal-content {
    border-radius: 24px;
    .ant-modal-body {
      padding: 0 30px 30px 30px;
      color: #2C363F;
    }
    .ant-modal-header {
      border-bottom: none;
      border-radius: 24px 24px 0 0;
    }
  }
  ${({ isMobile }: { isMobile: boolean }) => {
    if (isMobile) {
      return `
        width: 100% !important;
        max-width: unset !important;
        margin: unset;
        top: 0;
        position: unset;
        height: 100%;
        padding: 0;
        transform-origin: 0;
        .ant-modal-content {
          border-radius: 0;
        }
      `;
    }
  }}
`;

export const Logo = styled.div`
  img {
    width: 148px;
    height: auto;
  }
`;

export const Header = styled.div<any>`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 10em;
  button {
    ${({ hideBackButton }) => `visibility:${hideBackButton ? 'hidden' : 'visible'};`}
    position: absolute;
    left: 0;
  }
  & > :nth-child(2) {
    margin-top: 30px;
  }
`;

export const ModalGoBackButton = styled.span`
  top: 0;
  cursor: pointer;
  border: none;
  background-color: unset;
`;
