import colors from '@colors';
import { DefaultButton } from '@components/Button/styles';
import styled from 'styled-components';

export const SearchBox = styled.div`
  width: 100%;
  @media (max-width: 780px) {
    width: 100%;
    max-height: 250px;
    max-width: none;
  }

  @media (max-width: 480px) {
    width: 100%;
    max-height: none;
    max-width: none;
    padding: 0;
  }

  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 100%;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
    }
    height: 58px;
    border-radius: 24px 0 0 24px;
    box-shadow: 0 1px 11px 0 rgba(139, 106, 236, 0.2),
      0 0 10px 0 rgba(203, 186, 252, 0.15), 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    @media (max-width: 780px) {
      width: 100%;
      border-radius: 24px;
    }
  }

  span.ant-select-selection-placeholder {
    margin: auto;
    font-family: 'Onest', sans-serif;
    width: 50%;
    font-size: 14px;
    text-align: left;
    color: ${colors.textMediumEmphasis};
  }
`;

export const Search = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .ant-select {
    width: 70%;
  }

  button {
    height: 58px;
    background-color: ${colors.primary};
    transition: 300ms ease-out;
    border-radius: 0px 24px 24px 0px;
    cursor: pointer;
    @media (max-width: 780px) {
      width: 30%;
      border-radius: 24px;
    }

    @media (max-width: 480px) {
      width: 100%;
    }
  }

  @media (max-width: 480px) {
    flex-direction: column;
    justify-content: space-between;
    .ant-select {
      width: 100%;
      margin-bottom: 20px;
    }
  }
`;

export const SearchButton = styled(DefaultButton)`
  width: 100%;
  min-height: 56px;
  color: white;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 30%;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const Available = styled.div`
  display: flex;
  padding-top: 22px;
  font-family: Onest;
  font-size: 16px;
  align-items: baseline;

  span {
    margin-left: 10px;
    padding: 10px 20px 10px 20px;
    background-color: aliceblue;
    border-radius: 30px;
  }
`;
