import {
  signInService,
  signUpService,
  resetPasswordService,
  forgotPasswordService,
  checkResetPasswordActionCodeService,
} from '@services/auth';
import ISignup from 'types/SignupFormData';
import { ISignin } from 'types/SigninFormData';
import { getFirestoreUserService } from '@services/firebase';

const forgotPassword = (email: string) => async (dispatch: any) => {
  dispatch({ type: 'FETCH_FORGOT_PASS' });
  try {
    const { success, message, data } = await forgotPasswordService(email);
    if (success) {
      dispatch({ type: 'FETCH_FORGOT_PASS_SUCCESS', payload: data });
    } else {
      dispatch({ type: 'FETCH_FORGOT_PASS_ERROR', payload: message });
    }
  } catch (e) {
    dispatch({
      type: 'FETCH_FORGOT_PASS_ERROR',
      payload: 'Aconteceu um erro desconhecido.',
    });
  }
};

const resetPasswordState = () => ({ type: 'RESET_FORGOT_PASS_STATE' });

const resetSignInState = () => ({ type: 'RESET_SIGN_IN_STATE' });

const resetSignUpState = () => ({ type: 'RESET_SIGN_UP_STATE' });

const getUser = () => async (dispatch: any) => {
  try {
    const { data, success } = await getFirestoreUserService();

    if (success) {
      dispatch({
        type: 'USER_GET_USER',
        payload: data,
      });
    } else {
      dispatch({
        type: 'USER_NOT_SIGNED_IN',
      });
    }
  } catch (e) {
    console.log(e);
  }
};

const signUpUser = (userData: ISignup) => async (dispatch: any) => {
  dispatch({ type: 'FETCH_SIGN_UP' });
  try {
    const { success, message, data } = await signUpService(userData);
    if (success) {
      dispatch({ type: 'FETCH_SIGN_UP_SUCCESS', payload: data });
      const { success: successInService } = await signInService(userData);
      if (successInService) {
        dispatch(getUser());
      }
    } else {
      dispatch({ type: 'FETCH_SIGN_UP_ERROR', payload: message });
    }
  } catch (e) {
    dispatch({
      type: 'FETCH_SIGN_UP_ERROR',
      payload: 'Aconteceu um erro desconhecido.',
    });
  }
};

const signInUser = (userData: ISignin) => async (dispatch: any) => {
  dispatch({ type: 'FETCH_SIGN_IN' });
  try {
    const { success, message } = (await signInService(userData)) as any;

    if (success) {
      dispatch({ type: 'FETCH_SIGN_IN_SUCCESS' });
      dispatch(getUser());
    } else {
      dispatch({ type: 'FETCH_SIGN_IN_ERROR', payload: message });
    }
  } catch (e) {
    dispatch({
      type: 'FETCH_SIGN_IN_ERROR',
      payload: 'Aconteceu um erro desconhecido.',
    });
  }
};

const checkActionCode = (actionCode: string) => async (dispatch: any) => {
  dispatch({ type: 'FETCH_VERIFY_ACTION_CODE' });
  try {
    const { success } = await checkResetPasswordActionCodeService(actionCode);

    if (success) {
      dispatch({ type: 'FETCH_VERIFY_ACTION_CODE_SUCCESS' });
    } else {
      dispatch({ type: 'FETCH_VERIFY_ACTION_CODE_ERROR' });
    }
  } catch (e) {
    dispatch({
      type: 'FETCH_RESET_PASSWORD_ERROR',
      payload: 'Aconteceu um erro desconhecido.',
    });
  }
};

const resetPassword = (resetPasswordInput: any) => async (dispatch: any) => {
  dispatch({ type: 'FETCH_RESET_PASSWORD' });
  try {
    const { success, message } = await resetPasswordService(resetPasswordInput);
    if (success) {
      dispatch({ type: 'FETCH_RESET_PASSWORD_SUCCESS' });
    } else {
      dispatch({ type: 'FETCH_RESET_PASSWORD_ERROR', payload: message });
    }
  } catch (e) {
    dispatch({
      type: 'FETCH_RESET_PASSWORD_ERROR',
      payload: 'Aconteceu um erro desconhecido.',
    });
  }
};

const logoutUser = () => async (dispatch: any) => {
  dispatch({ type: 'USER_LOGOUT' });
  dispatch({ type: 'RESET_SIGN_IN_STATE' });
};

export {
  getUser,
  logoutUser,
  signUpUser,
  signInUser,
  resetPassword,
  forgotPassword,
  checkActionCode,
  resetSignInState,
  resetSignUpState,
  resetPasswordState,
};
