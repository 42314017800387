// @ts-ignore
import FontAwesome_ttf from 'react-web-vector-icons/fonts/FontAwesome.ttf';
// @ts-ignore
import Entypo_ttf from 'react-web-vector-icons/fonts/Entypo.ttf';
// @ts-ignore
import Antd_ttf from 'react-web-vector-icons/fonts/AntDesign.ttf';
// @ts-ignore
import Ionicons_ttf from 'react-web-vector-icons/fonts/Ionicons.ttf';
// @ts-ignore
import MaterialCommunityIcons_ttf from 'react-web-vector-icons/fonts/MaterialCommunityIcons.ttf';
// @ts-ignore
import Feather_ttf from 'react-web-vector-icons/fonts/Feather.ttf';
// @ts-ignore
import Foundation_ttf from 'react-web-vector-icons/fonts/Foundation.ttf';

const IconsCSS = `
@font-face {
  font-family: AntDesign;
  font-style: normal;
  font-weight: 400;
  src: url(${Antd_ttf}) format('truetype')
}

@font-face {
  font-family: Entypo;
  font-style: normal;
  font-weight: 400;
  src: url(${Entypo_ttf}) format('truetype')
  font-display: swap;
}

@font-face {
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  src: url(${FontAwesome_ttf}) format('truetype')
  font-display: swap;
}

@font-face {
  font-family: Feather;
  font-style: normal;
  font-weight: 400;
  src: url(${Feather_ttf}) format('truetype')
  font-display: swap;
}

@font-face {
  font-family: Ionicons;
  font-style: normal;
  font-weight: 400;
  src: url(${Ionicons_ttf}) format('truetype')
}

@font-face {
  font-family: MaterialCommunityIcons;
  font-style: normal;
  font-weight: 400;
  src: url(${MaterialCommunityIcons_ttf}) format('truetype')
}

@font-face {
  font-family: FontAwesome5;
  font-style: normal;
  font-weight: 400;
  src: url(fonts/FontAwesome5.ttf) format('truetype')
}

@font-face {
  font-family: FontAwesome5Brands;
  font-style: normal;
  font-weight: 400;
  src: url(fonts/FontAwesome5_Brands.ttf) format('truetype')
}

@font-face {
  font-family: Foundation;
  font-style: normal;
  font-weight: 400;
  src: url(${Foundation_ttf}) format('truetype')
}
`;

const style = document.createElement('style') as any;
style.type = 'text/css';
if (style.styleSheet) style.styleSheet.cssText = IconsCSS;
else style.appendChild(document.createTextNode(IconsCSS));

document.head.appendChild(style);
