// eslint-disable-next-line
import React, { ReactElement } from 'react';
import SkeletonImage from '@components/SkeletonImage';
import {
  Container,
  SkeletonCardBody,
  SkeletonCardHeader,
  CardSkeletonHeading,
} from './styles';

export function CardSkeleton(): ReactElement {
  return (
    <Container>
      <SkeletonCardHeader>
        <SkeletonImage />
      </SkeletonCardHeader>
      <SkeletonCardBody active />
    </Container>
  );
}

export function CardHeadingSkeleton(): ReactElement {
  return (
    <CardSkeletonHeading active />
  );
}
