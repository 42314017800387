import yup from '@lib/yup';

export const validateForgotPasswordSchema = yup.object({
  email: yup.string().email().required(),
});

export const validateChangePasswordSchema = yup.object({
  currentPass: yup
    .string()
    .test('len', 'Deve ter no mínimo 8 caracteres.', (val) => (val ? val.length >= 8 : false))
    .required(),
  newPass: yup
    .string()
    .test('len', 'Deve ter no mínimo 8 caracteres.', (val) => (val ? val.length >= 8 : false))
    .matches(
      /(?:\d+[a-z]|[a-z]+\d)[a-z\d]*/,
      'Deve conter letras e números ou símbolos.',
    )
    .required(),
  confirmNewPass: yup
    .string()
    .oneOf([yup.ref('newPass'), undefined], 'As senhas não coincidem.')
    .required(),
});

export const validateResetPasswordSchema = yup.object({
  password: yup
    .string()
    .test('len', 'Deve ter no mínimo 8 caracteres.', (val) => (val ? val.length >= 8 : false))
    .matches(
      /(?:\d+[a-z]|[a-z]+\d)[a-z\d]*/,
      'Deve conter letras e números ou símbolos.',
    )
    .required(),

  password_confirm: yup
    .string()
    .oneOf([yup.ref('password'), undefined], 'As senhas não coincidem.')
    .required(),
});

export const validateSignupSchema = yup.object({
  full_name: yup.string().required(),
  email: yup.string().email().required(),
  terms: yup
    .boolean()
    .oneOf([true], 'Aceite os termos e condições para continuar.'),
  password: yup
    .string()
    .test('len', 'Deve ter no mínimo 8 caracteres.', (val) => (val ? val.length >= 8 : false))
    .matches(
      /(?:\d+[a-z]|[a-z]+\d)[a-z\d]*/,
      'Deve conter letras e números ou símbolos.',
    )
    .required(),
  password_confirm: yup
    .string()
    .oneOf([yup.ref('password'), undefined], 'As senhas não coincidem.')
    .required(),
});

export const validateSigninSchema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

export const validatePasswordSchema = yup.object({
  password: yup.string().required(),
});
