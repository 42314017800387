import { combineReducers } from 'redux';
import auth from './auth';
import modal from './modal';
import simulator from './simulator';
import imgCache from './imgCache';
import hideHeaderFooter from './hideHeaderFooter';
import contactUsSlice from './contactUs';

const rootReducer = combineReducers({
  auth,
  modal,
  imgCache,
  simulator,
  contactUsSlice,
  hideHeaderFooter,
});

export default rootReducer;
