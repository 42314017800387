import colors from '@colors';

function useStyledErrorInput(errortype: string | null) {
  switch (errortype) {
    case 'warn':
      return `
              border: 2px solid ${colors.yellowWarn};
              &:hover {
                border: 2px solid ${colors.yellowWarn};
              }
              &:focus {
                border: 2px solid ${colors.yellowWarn};
              }
          `;
    case 'error':
      return `
              border: 2px solid ${colors.redError};
              &:hover {
                border: 2px solid ${colors.redError};
              }
              &:focus {
                border: 2px solid ${colors.redError};
              }
          `;

    default:
      return '';
  }
}

export default useStyledErrorInput;
