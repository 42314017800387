// eslint-disable-next-line
import React from 'react';
import Checkbox from '@components/Checkbox';
import { Controller, useFormContext } from 'react-hook-form';
import { Container } from './styles';

function RememberMe() {
  const { control } = useFormContext();

  return (
    <Container>
      <Controller
        render={(props) => (
          <Checkbox
            id="remember-me"
            onChange={(e: any) => props.onChange(e.target.checked)}
            checked={props.value}
          />
        )}
        name="rememberMe"
        control={control}
      />
      <label htmlFor="remember-me">Lembrar-me</label>
    </Container>
  );
}

export default RememberMe;
