import styled from 'styled-components';
import colors from '@colors';

export const CardBox = styled.div`
  cursor: pointer;
  :hover {
    transform: scale(1.05);
  }
  transition: transform 200ms;
  .ant-avatar.ant-avatar-icon {
    margin-right: 3px;
    font-size: 18px;
  }

  .ant-card {
    box-shadow: 0 1px 11px 0 rgba(139, 106, 236, 0.2),
      0 0 10px 0 rgba(203, 186, 252, 0.15), 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    margin-right: 30px;
    margin-bottom: 60px;
    border-radius: 20px;
    @media (max-width: 1480px) {
      width: 370px;
      height: 425px;
    }
    @media (max-width: 1024px) {
      width: 324px;
      height: 425px;
    }
    @media (max-width: 480px) {
      margin-right: 0;
    }
    width: 420px;
    height: 464px;
  }

  .ant-avatar-square {
    border-radius: 24px;
    background-color: #FF4E00;
  }

  .ant-card-cover img {
    margin-top: -65px;
    border-radius: 20px 20px 0 0;
  }

  .ant-card-head-title {
    z-index: 2;
  }

  .ant-card-extra {
    z-index: 3;
    span {
      svg {
        circle {
          stroke: transparent;
        }
      }
    }
  }

  .ant-card-body {
    padding: 5%;
    .ant-card-meta {
      .ant-card-meta-detail {
        & > div:not(:last-child) {
          margin-bottom: 2px;
        }
        .ant-card-meta-description {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          color: ${colors.textHighEmphasis};
        }
        .ant-card-meta-title {
          font-weight: bold;
          color: ${colors.textHighEmphasis};
        }
      }
    }
  }
`;

export const Cost = styled.div`
  color: ${colors.textHighEmphasis};
`;

export const Details = styled.div`
  span {
    color: ${colors.primary};
    cursor: pointer;
  }
`;

export const Description = styled.div`
  margin-top: 12px;
  @media (max-width: 1480px) {
    margin-top: 6px;
  }

  @media (max-width: 480px) {
    margin-top: 5px;
  }
  font-size: 0.9em;
  line-height: 1.43;
  opacity: 0.7;
  color: ${colors.textMediumEmphasis};
`;

export const CardOptions = styled.div`
  font-size: 12px;
  line-height: 20px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 17px;
  @media (max-width: 1480px) {
    margin-top: 20px;
  }

  @media (max-width: 480px) {
    margin-top: 10px;
  }
`;

export const PreLaunch = styled.div`
  span {
    color: white;
    background: ${colors.primary};
    padding: 10px;
    border-radius: 30px;
  }

  @media (max-width: 480px) {
    span {
      padding: 7px;
    }
  }
`;

export const ImageContainer = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1480px) {
    width: 370px;
    height: ${425 / 1.6 - 70}px;
  }
  @media (max-width: 1024px) {
    width: 324px;
    height: ${425 / 1.6 - 70}px;
  }
  width: 420px;
  height: ${464 / 1.6 - 70}px;
  img {
    @media (max-width: 1480px) {
      width: 370px;
      height: ${425 / 1.6}px;
    }
    @media (max-width: 1024px) {
      width: 324px;
      height: ${425 / 1.6}px;
    }
    width: 420px;
    height: ${464 / 1.6}px;
    object-fit: cover;
  }
`;
