import axios from 'axios';
import getFirebaseApp from '@lib/firebase';
import { BASE_URL } from '@constants/enviroment';

const apiService = axios.create({
  baseURL: `${BASE_URL}`,
});

const apiServiceWithUserAuthenticated = axios.create({
  baseURL: `${BASE_URL}`,
});

// eslint-disable-next-line
const promise = new Promise(async (resolve, reject) => {
  const { firebaseApp } = await getFirebaseApp();
  firebaseApp.auth().onAuthStateChanged(async (user: any) => {
    if (user) {
      const token = await user.getIdToken();
      resolve(token);
    } else {
      reject(new Error('Token not found or nonexistent'));
    }
  });
});

apiServiceWithUserAuthenticated.interceptors.request.use(async (request) => {
  try {
    const token = await promise;
    request.headers.authorization = `Bearer ${token}`;
  } catch (e) {
    const { firebaseApp } = await getFirebaseApp();
    const user = firebaseApp.auth().currentUser;

    if (!user) {
      throw new axios.Cancel('Token or user not informed');
    }
    const token = await user.getIdToken();
    request.headers.authorization = `Bearer ${token}`;
  }
  return request;
}, (error) => Promise.reject(error));

export {
  apiService,
  apiServiceWithUserAuthenticated,
};
