// eslint-disable-next-line
import React, { ReactElement } from 'react';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { AlertError } from './styles';
// @ts-ignore

interface Props {
  message: string;
}

function AlertMessage({ message }: Props): ReactElement {
  return (
    <AlertError>
      <IoIosCloseCircleOutline fill="#ff4d4f" size="1.2em" />
      <span>{message}</span>
    </AlertError>
  );
}

export default AlertMessage;
