import IResponse from 'types/Response';
import getFirebaseApp from '@lib/firebase';
import ISignup from 'types/SignupFormData';
import { apiService, apiServiceWithUserAuthenticated } from '@services/API';

async function forgotPasswordService(email: string): Promise<IResponse> {
  const response = await apiService.post('/firebase/auth/forgotpass', email);
  return response.data;
}

async function checkResetPasswordActionCodeService(
  actionCode: string,
): Promise<IResponse> {
  const response = await apiServiceWithUserAuthenticated.get(`/firebase/auth/actioncode/${actionCode}`);
  return response.data;
}

async function resetPasswordService(resetPassword: any): Promise<IResponse> {
  const response = await apiServiceWithUserAuthenticated.post('/firebase/auth/resetpass/', resetPassword);
  return response.data;
}

async function signInService({
  email,
  password,
  rememberMe,
}: any): Promise<IResponse> {
  try {
    const { firebase, firebaseApp } = await getFirebaseApp();
    const { user } = await firebaseApp
      .auth()
      .signInWithEmailAndPassword(email, password);
    firebaseApp
      .auth()
      .setPersistence(
        rememberMe
          ? firebase.auth.Auth.Persistence.LOCAL
          : firebase.auth.Auth.Persistence.SESSION,
      );

    if (user) {
      const token: string = await user.getIdToken();
      return {
        data: token,
        success: true,
        message: '',
      };
    }

    return {
      data: '',
      success: false,
      message: '',
    };
  } catch (e) {
    if (e instanceof Error) {
      return {
        data: [],
        success: false,
        message: e.message,
      };
    }
    return {
      data: [],
      success: false,
      message: 'Error',
    };
  }
}

async function signUpService(userData: ISignup): Promise<IResponse> {
  const response = await apiService.post('/firebase/auth/signup', userData);
  return response.data;
}

async function loginWithFacebook(): Promise<IResponse> {
  const { firebase } = await getFirebaseApp();
  const provider = new firebase.auth.FacebookAuthProvider();
  try {
    const { user } = await firebase.auth().signInWithPopup(provider);
    if (user) {
      const {
        email,
        displayName,
        stsTokenManager: { accessToken },
      } = user.toJSON() as any;
      const data: ISignup = {
        email,
        full_name: displayName,
        terms: true,
      };
      await signUpService(data);
      return {
        data: accessToken,
        success: true,
        message: '',
      };
    }
    return {
      data: null,
      success: false,
      message: '',
    };
  } catch (e) {
    if (e instanceof Error) {
      return {
        success: false,
        data: null,
        message: e.message,
      };
    }

    return {
      success: false,
      data: null,
      message: 'Error',
    };
  }
}

async function loginWithGoogle(): Promise<IResponse> {
  const { firebase } = await getFirebaseApp();
  const provider = new firebase.auth.GoogleAuthProvider();
  try {
    const { user } = await firebase.auth().signInWithPopup(provider);
    if (user) {
      const {
        email,
        displayName,
        stsTokenManager: { accessToken },
      }: any = user.toJSON();
      const data: ISignup = {
        email,
        full_name: displayName,
        terms: true,
      };
      await signUpService(data);
      return {
        data: accessToken,
        success: true,
        message: '',
      };
    }
    return {
      data: null,
      success: false,
      message: '',
    };
  } catch (e) {
    if (e instanceof Error) {
      return {
        success: false,
        data: null,
        message: e.message,
      };
    }

    return {
      success: false,
      data: null,
      message: '',
    };
  }
}

export {
  signInService,
  signUpService,
  loginWithGoogle,
  loginWithFacebook,
  resetPasswordService,
  forgotPasswordService,
  checkResetPasswordActionCodeService,
};
