// eslint-disable-next-line
import React, { ReactElement } from 'react';
import IHeading from 'types/Heading';
import { DefaultHeading } from './styles';

function Heading({ type, children }: IHeading): ReactElement {
  return (
    <DefaultHeading type={type}>
      {children}
    </DefaultHeading>
  );
}

export default Heading;
