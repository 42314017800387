import { useEffect, useState } from 'react';
import ISelectBox from 'types/SelectBox';

interface IActiveOptions {
  id: number;
  value: number | string;
  checked: boolean;
}

export function useSelectBox({ options, reset }: ISelectBox) {
  const [activeOptions, setActiveOptions] = useState<IActiveOptions[]>([]);

  useEffect(() => {
    if (options) {
      options.forEach((value, index) => {
        setActiveOptions((prevState) => [
          ...prevState,
          {
            value,
            id: index,
            checked: false,
          },
        ]);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function setActive(checkedId: number) {
    setActiveOptions((prevState) => prevState.map(({ id, checked, ...rest }) => {
      if (id === checkedId) {
        return {
          ...rest,
          id,
          checked: !checked,
        };
      }
      return {
        ...rest,
        id,
        checked,
      };
    }));
  }

  useEffect(() => {
    if (reset) {
      setActiveOptions((prevState) => prevState.map(({ checked, ...rest }) => ({
        ...rest,
        checked: false,
      })));
    }
  }, [reset]);

  return { activeOptions, setActive };
}

export default useSelectBox;
