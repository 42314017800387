import colors from '@colors';
import styled from 'styled-components';

export const AlertError = styled.div`
  width: 370px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px;
  color: ${colors.textHighEmphasis};
  height: fit-content;
  border-radius: 24px;
  border: solid 1px #ffccc7;
  background-color: #fff1f0;
  i {
    margin-right: 5px;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;
