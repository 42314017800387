// eslint-disable-next-line
import React, { ReactElement } from 'react';
import LoadingSpinner from '@components/LoadingSpinner';
import { DefaultFloatingAlertMessage, SecureOverlay } from './styles';

interface Props {
  message: string;
  show: boolean;
}

function FloatingAlertMessage({ message, show }: Props): ReactElement | null {
  if (!show) {
    return null;
  }
  return (
    <SecureOverlay>
      <DefaultFloatingAlertMessage>
        <LoadingSpinner />
        <span>{message}</span>
      </DefaultFloatingAlertMessage>
    </SecureOverlay>
  );
}

FloatingAlertMessage.defaultProps = {
  message: '',
  show: false,
};

export default FloatingAlertMessage;
