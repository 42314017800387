import colors from '@colors';
import styled from 'styled-components';

export const FooterBox = styled.div`
  width: 100%;
  margin: 0 -4.55%;
  background: ${colors.secondary};
  display: flex;
  justify-content: space-between;
  z-index: 1;
  svg {
    color: #fff;
    font-size: 19px;
    padding-top: 2px;
  }

  span {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
  }

  @media (max-width: 480px) {
    flex-direction: column-reverse;
    span {      
      font-size: 14px;
    }
  }
`;

export const Left = styled.div`
  padding: 14px 0px 14px 14px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 480px) {
    padding-left: 14px;
  }
`;

export const Right = styled.div`
  padding: 14px 14px 14px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  flex-grow: 0.1;
  justify-content: space-around;
  svg, div, span {
    cursor: pointer;
  }

  @media (max-width: 480px) {
    flex-direction: row-reverse;    
    display: contents;
  }
`;

export const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  svg, span {
    margin-left: 14px;
  }

  @media (max-width: 480px) {    
    padding-top: 10px;    
    justify-content: center;
  }  
`;

export const Infos = styled.div`
  @media (max-width: 480px) {    
    padding-top: 10px;
    display: flex;
    justify-content: center;
    gap: 5px;
  }  
`;
