import { useEffect, useLayoutEffect, useState } from 'react';
import getFirebaseApp from '@lib/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import IStore from 'types/Store';
import { getUser, logoutUser } from '@actions/auth';

function useGetUser() {
  const {
    user,
    userSignedIn,
    logout: useHasLoggedOut,
  } = useSelector(({ auth: { getUser } }: IStore) => getUser);
  const [localUser, setLocalUser] = useState<any>(user);
  const [loadingUser, setLoadingUser] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();

  useLayoutEffect(() => {
    if (!user) {
      dispatch(getUser());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userSignedIn !== null) {
      if (userSignedIn) {
        setLocalUser(user);
        setLoadingUser(false);
      } else {
        setLocalUser(null);
        setLoadingUser(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSignedIn]);

  useEffect(() => {
    setLocalUser(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.favorites?.length]);

  useEffect(() => {
    setLocalUser(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.simulations?.length]);

  async function updateUserStore() {
    dispatch(getUser());
  }

  async function logout() {
    const { firebaseApp } = await getFirebaseApp();
    await firebaseApp.auth().signOut();
    dispatch(logoutUser());
    history.push('/');
  }

  return {
    user: localUser,
    loadingUser,
    useHasLoggedOut,
    logout,
    updateUserStore,
  };
}

export default useGetUser;
