// eslint-disable-next-line
import React, { useEffect } from 'react';
import { useGetUser } from '@hooks';
import { Redirect, Route, RouteProps } from 'react-router-dom';

interface Props extends RouteProps {
  component: React.ComponentType;
  name: string;
}

const PrivateRoute = ({ component: Component, ...rest }: Props) => {
  const { user, loadingUser, useHasLoggedOut } = useGetUser();

  useEffect(() => {
    if (useHasLoggedOut) {
      window.location.replace('/');
    }
  }, [useHasLoggedOut]);

  return (
    <Route
      {...rest}
      render={(props: any) => {
        if (loadingUser) {
          return (<></>);
        }
        if (user) {
          return <Component {...props} />;
        }
        if (!useHasLoggedOut) {
          return <Redirect to="/404" />;
        }
        return <Redirect to="/404" />;
      }}
    />
  );
};

export default PrivateRoute;
